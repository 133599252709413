import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_EBAY_SALES_PROFITS_AND_ROI_QUERY } from "@/graphql/queries/ebay/sold/ebaySoldQueries";
import { subDays, startOfToday, endOfToday } from "date-fns";

const useEbaySalesProfitsAndRoi = () => {
    const [filters, setFilters] = useState({
        ebaySaleSoldAtGte: subDays(startOfToday(), 30).toISOString(),
        ebaySaleSoldAtLte: endOfToday().toISOString(),
        tags: [],
        sourceLocationId: null,
        itemCategoryId: null,
        itemBrandId: null,
    });

    const { data, loading, error, refetch } = useQuery(
        GET_EBAY_SALES_PROFITS_AND_ROI_QUERY,
        {
            variables: { ...filters },
        },
    );

    useEffect(() => {
        if (data) {
            // eslint-disable-next-line no-empty
        }
    }, [data]);

    useEffect(() => {
        refetch(filters);
    }, [filters, refetch]);

    const handleFilterChange = (name: string, value: any) => {
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const applyFilters = () => {
        refetch(filters);
    };

    const resetFilters = () => {
        const defaultFilters = {
            ebaySaleSoldAtGte: subDays(startOfToday(), 30).toISOString(),
            ebaySaleSoldAtLte: endOfToday().toISOString(),
            tags: [],
            sourceLocationId: null,
            itemCategoryId: null,
            itemBrandId: null,
        };
        setFilters(defaultFilters);
        refetch(defaultFilters);
    };

    return {
        data: data?.saleProfitAndRois,
        loading,
        error,
        filters,
        setFilters: handleFilterChange,
        applyFilters,
        resetFilters,
    };
};

export default useEbaySalesProfitsAndRoi;
