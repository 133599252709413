import { gql } from "@apollo/client";

export const GET_EBAY_ACCOUNT_SETTINGS_QUERY = gql`
    query GetEbayAccountSettingsQuery {
        ebayAccountSettings {
            id
            matchPostagePriceWithCost
            screenshotTextAlignment
        }
    }
`;
