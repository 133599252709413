import { gql } from "@apollo/client";

export const UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION = gql`
    mutation UpdateEbayAccountSettingsMutation(
        $matchPostagePriceWithCost: Boolean
    ) {
        updateEbayAccountSettings(
            matchPostagePriceWithCost: $matchPostagePriceWithCost
        ) {
            settings {
                id
                matchPostagePriceWithCost
            }
        }
    }
`;

export const INITIAL_UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION = gql`
    mutation InitialUpdateEbayAccountSettingsMutation(
        $matchPostagePriceWithCost: Boolean
    ) {
        initialUpdateEbayAccountSettings(
            matchPostagePriceWithCost: $matchPostagePriceWithCost
        ) {
            settings {
                id
                matchPostagePriceWithCost
            }
        }
    }
`;

export const UPDATE_USER_MUTATION = gql`
    mutation UpdateUserMutation(
        $email: String
        $firstName: String
        $lastName: String
    ) {
        updateUser(email: $email, firstName: $firstName, lastName: $lastName) {
            message
            status
            success
            user {
                id
                firstName
                lastName
                email
            }
        }
    }
`;

export const RESET_USER_PASSWORD_MUTATION = gql`
    mutation ResetUserPasswordMutation(
        $newPassword: String!
        $oldPassword: String!
    ) {
        resetUserPassword(
            newPassword: $newPassword
            oldPassword: $oldPassword
        ) {
            success
            message
        }
    }
`;
