import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/hooks/auth/useAuth";
import LogoComponent from "@/components/logo/LogoComponent";

export default function SignupPage() {
    const navigate = useNavigate();
    const { signupUser } = useAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [message, setMessage] = useState<string | null>(null);
    const [messageType, setMessageType] = useState<"success" | "error">(
        "error",
    );
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const validatePassword = (password: string): boolean => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/;
        return passwordRegex.test(password);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setPasswordError(
            validatePassword(e.target.value)
                ? ""
                : "Password must be 12+ characters, contain a capital letter, lowercase letter and a number.",
        );
    };

    const handleConfirmPasswordChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError(
            e.target.value === password ? "" : "Passwords do not match.",
        );
    };

    const handleSignup = async (e: React.FormEvent) => {
        e.preventDefault();
        const result = await signupUser(email, password, firstName, lastName);

        if (!validatePassword(password)) {
            setPasswordError(
                "Password must be 12+ characters, contain a capital letter, lowercase letter and a number.",
            );
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match.");
            return;
        }

        if (result.success) {
            setMessageType("success");
            setMessage(result.message);
            navigate("/email-verification");
        } else {
            setMessageType("error");
            setMessage(result.message);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Sign up to FlipLytics
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSignup}>
                    <div>
                        <label
                            htmlFor="firstName"
                            className="block text-sm font-medium leading-6 dark:text-white"
                        >
                            First Name
                        </label>
                        <div className="mt-2">
                            <input
                                id="firstName"
                                name="firstName"
                                type="text"
                                autoComplete="given-name"
                                required
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-brand focus:ring-2 focus:ring-inset focus:ring-brand focus-visible:ring-brand dark:text-white dark:ring-white/10 dark:focus:ring-brand dark:focus-visible:ring-brand sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <label
                            htmlFor="lastName"
                            className="block text-sm font-medium leading-6 dark:text-white"
                        >
                            Last Name
                        </label>
                        <div className="mt-2">
                            <input
                                id="lastName"
                                name="lastName"
                                type="text"
                                autoComplete="family-name"
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-brand focus:ring-2 focus:ring-inset focus:ring-brand focus-visible:ring-brand dark:text-white dark:ring-white/10 dark:focus:ring-brand dark:focus-visible:ring-brand sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 dark:text-white"
                        >
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-brand focus:ring-2 focus:ring-inset focus:ring-brand focus-visible:ring-brand dark:text-white dark:ring-white/10 dark:focus:ring-brand dark:focus-visible:ring-brand sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium leading-6 dark:text-white"
                            >
                                Password
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                value={password}
                                onChange={handlePasswordChange}
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-brand focus:ring-2 focus:ring-inset focus:ring-brand focus-visible:ring-brand dark:text-white dark:ring-white/10 dark:focus:ring-brand dark:focus-visible:ring-brand sm:text-sm sm:leading-6"
                            />
                            {passwordError && (
                                <div className="mt-2 text-sm text-red-500">
                                    {passwordError}
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label
                                htmlFor="confirmPassword"
                                className="block text-sm font-medium leading-6 dark:text-white"
                            >
                                Confirm Password
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                autoComplete="new-password"
                                required
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-brand focus:ring-2 focus:ring-inset focus:ring-brand focus-visible:ring-brand dark:text-white dark:ring-white/10 dark:focus:ring-brand dark:focus-visible:ring-brand sm:text-sm sm:leading-6"
                            />
                            {confirmPasswordError && (
                                <div className="mt-2 text-sm text-red-500">
                                    {confirmPasswordError}
                                </div>
                            )}
                        </div>
                    </div>

                    {message && (
                        <div
                            className={`mt-2 text-sm ${messageType === "error" ? "text-red-500" : "text-green-500"}`}
                        >
                            {message}
                        </div>
                    )}

                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
                        >
                            Sign up
                        </button>
                    </div>
                </form>

                <p className="mt-10 text-center text-sm dark:text-gray-400">
                    Already registered?{" "}
                    <a
                        href="/login"
                        className="font-semibold leading-6 text-brand hover:text-brand"
                    >
                        Login here
                    </a>
                </p>
            </div>
        </div>
    );
}
