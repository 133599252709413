import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ReactNode } from "react";

const STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

interface StripeProviderProps {
    children: ReactNode;
}

const StripeProvider = ({ children }: StripeProviderProps): JSX.Element => {
    return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
