import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { AuthContext } from "@/providers/AuthProvider";
import { useQuery, useMutation } from "@apollo/client";
import { GET_TIER_PRICING_QUERY } from "@/graphql/queries/paymentQueries";
import { CREATE_CHECKOUT_SESSION_MUTATION } from "@/graphql/mutations/paymentMutations";
import LogoComponent from "@/components/logo/LogoComponent";
import CircularLoader from "@/components/loading/LoadingComponent";

const CheckoutPage: React.FC = () => {
    console.log("CheckoutPage");

    const { subscription, loading: authLoading } = useContext(AuthContext);

    useEffect(() => {
        if (
            !authLoading &&
            subscription &&
            (subscription.status === "active" ||
                subscription.status === "trialing")
        ) {
            window.location.href = "/dashboard";
        }
    }, [authLoading, subscription]);

    // Fetch tier pricing data
    const { data, loading, error } = useQuery(GET_TIER_PRICING_QUERY, {
        errorPolicy: "all", // Allow partial data even if there's an error
    });

    // Mutation to create a checkout session
    const [createCheckoutSession, { loading: mutationLoading }] = useMutation(
        CREATE_CHECKOUT_SESSION_MUTATION,
    );

    // State to track the selected billing cycle
    const [billingCycle, setBillingCycle] = useState<"MONTHLY" | "ANNUALLY">(
        "MONTHLY",
    );

    // Debugging output to check the fetched data
    console.log({ data, loading, error });

    // Handle loading state
    if (loading) return <CircularLoader />;

    // Handle error state
    if (error) return <p>Error loading tier pricing</p>;

    // Handle case where data might be missing
    if (!data || !data.tierPricing) return <p>No pricing data available</p>;

    // Function to handle billing cycle change
    const handleBillingCycleChange = (cycle: "MONTHLY" | "ANNUALLY") => {
        setBillingCycle(cycle);
    };

    // Function to handle checkout
    const handleCheckout = async () => {
        try {
            const { data } = await createCheckoutSession({
                variables: { billingCycle: billingCycle },
            });
            const checkoutUrl = data?.createCheckoutSession?.checkoutUrl;
            if (checkoutUrl) {
                window.location.href = checkoutUrl;
            }
        } catch (error) {
            console.error("Error creating checkout session", error);
        }
    };

    // Function to get currency symbol based on currency code
    const currencySymbol = (currency: string) => {
        switch (currency.toUpperCase()) {
            case "USD":
                return "$";
            case "EUR":
                return "€";
            case "GBP":
                return "£";
            default:
                return currency;
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Choose Your Plan
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="w-full rounded-lg bg-white p-8 shadow-md dark:bg-gray-800">
                    <h3 className="mb-4 text-xl font-semibold dark:text-white">
                        Your Tier: {data.tierPricing.tier}
                    </h3>
                    <p className="mb-2 dark:text-white">
                        Monthly Price:{" "}
                        {currencySymbol(data.tierPricing.currency)}
                        {data.tierPricing.monthlyPrice}
                    </p>
                    <p className="mb-4 dark:text-white">
                        Yearly Price:{" "}
                        {currencySymbol(data.tierPricing.currency)}
                        {data.tierPricing.yearlyPrice}
                    </p>

                    <p className="mb-6 text-sm dark:text-gray-400">
                        Your tier is determined by your eBay store type.
                        Depending on whether you have an Anchor, Featured, or
                        Basic eBay store, we have tailored pricing plans that
                        suit your needs. If you upgrade or downgrade your eBay
                        store, your tier and corresponding pricing may change.
                    </p>

                    <div className="mb-6 flex justify-between">
                        <button
                            onClick={() => handleBillingCycleChange("MONTHLY")}
                            className={`mr-2 w-full rounded-md px-4 py-2 focus:outline-none ${billingCycle === "MONTHLY" ? "bg-brand text-white" : "bg-gray-200 dark:bg-gray-700"} ${mutationLoading ? "cursor-not-allowed opacity-50" : "hover:bg-brand-light"}`}
                            disabled={mutationLoading}
                        >
                            Pay Monthly
                        </button>
                        <button
                            onClick={() => handleBillingCycleChange("ANNUALLY")}
                            className={`ml-2 w-full rounded-md px-4 py-2 focus:outline-none ${billingCycle === "ANNUALLY" ? "bg-brand text-white" : "bg-gray-200 dark:bg-gray-700"} ${mutationLoading ? "cursor-not-allowed opacity-50" : "hover:bg-brand-light"}`}
                            disabled={mutationLoading}
                        >
                            Pay Annually
                        </button>
                    </div>

                    <button
                        onClick={handleCheckout}
                        className={`flex w-full justify-center rounded-md bg-green-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 ${mutationLoading ? "cursor-not-allowed opacity-50" : "hover:bg-green-600"}`}
                        disabled={mutationLoading}
                    >
                        Proceed to Checkout
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CheckoutPage;
