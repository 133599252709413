import React, { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

interface TooltipProps {
    text: string;
}

const Tooltip: React.FC<TooltipProps> = ({ text }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className="relative ml-1 inline-block">
            {/* Info Icon */}
            <div
                className="flex cursor-pointer items-center justify-center"
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
                onClick={() => setIsVisible(!isVisible)}
            >
                <InformationCircleIcon className="h-5 w-5 text-gray-700 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100" />
            </div>

            {/* Tooltip */}
            {isVisible && (
                <div className="absolute -left-10 top-8 z-10 w-60 rounded-md bg-gray-700 p-2 text-sm text-white shadow-md">
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
