import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { UPDATE_EBAY_SALE_MUTATION } from "@/graphql/mutations/ebayMutations";
import { useMutation } from "@apollo/client";
import Tooltip from "@/components/dashboard/general/TooltipComponent";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    sale: any;
}

const decodeBase64Id = (encodedId: string): string => {
    const decodedString = atob(encodedId);
    return decodedString.split(":")[1]; // Assuming the format is "Type:ID"
};

const EditSaleModal: React.FC<ModalProps> = ({ isOpen, closeModal, sale }) => {
    const navigate = useNavigate();

    const [updateEbaySale] = useMutation(UPDATE_EBAY_SALE_MUTATION);

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);

        // Convert the id from base64 to string
        const saleId = decodeBase64Id(sale.id);

        const variables: any = { saleId: saleId };

        const shippingCost = formData.get("shippingCost");
        if (shippingCost) {
            variables.shippingCost = parseFloat(shippingCost as string).toFixed(
                2,
            );
        }

        try {
            await updateEbaySale({ variables });
            closeModal();
            navigate(0);
        } catch (error) {
            console.error("Error updating eBay sale:", error);
        }
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 border-2 bg-white bg-opacity-75 transition-opacity dark:bg-gray-500 dark:opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg border-2 border-gray-900 bg-white px-4 pb-4 text-left shadow-xl transition-all dark:border-0 dark:bg-gray-900 sm:my-8 sm:w-full sm:max-w-sm sm:p-6 lg:pt-4">
                                <div className="mb-3">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 dark:text-white"
                                    >
                                        Edit Sale Details
                                    </Dialog.Title>
                                </div>
                                <div className="mb-3">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="mt-1">
                                            <div className="flex items-center">
                                                <label
                                                    htmlFor="shippingCost"
                                                    className="block text-sm font-medium dark:text-white"
                                                >
                                                    Shipping Cost
                                                </label>
                                                <Tooltip text="Enter how much you actually paid for shipping." />
                                            </div>
                                            <input
                                                type="number"
                                                name="shippingCost"
                                                id="shippingCost"
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                defaultValue={
                                                    sale?.shippingCost
                                                }
                                                step={0.01}
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand dark:text-white"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="flex w-full justify-center rounded-md border-2 border-gray-900 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 dark:border-0 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EditSaleModal;
