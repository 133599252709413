import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PAGINATED_SOURCE_LOCATIONS_QUERY } from "@/graphql/queries/ebay/source-locations/ebaySourceLocationQueries";

const useSourceLocations = () => {
    const [pagination, setPagination] = useState({
        after: null,
        before: null,
    });

    const { data, loading, error } = useQuery(
        GET_PAGINATED_SOURCE_LOCATIONS_QUERY,
        {
            variables: { ...pagination },
        },
    );

    const handleNextPage = () => {
        if (data?.paginatedEbayItemTags?.pageInfo?.hasNextPage) {
            setPagination((prev) => ({
                ...prev,
                after: data.paginatedSourceLocations.pageInfo.endCursor,
            }));
        }
    };

    const handlePreviousPage = () => {
        if (data?.paginatedSourceLocations?.pageInfo?.hasPreviousPage) {
            setPagination((prev) => ({
                ...prev,
                before: data.paginatedSourceLocations.pageInfo.startCursor,
            }));
        }
    };

    return {
        data,
        loading,
        error,
        handleNextPage,
        handlePreviousPage,
        pageInfo: data?.paginatedSourceLocations?.pageInfo || {},
    };
};

export default useSourceLocations;
