import React, { useEffect, useState } from "react";
import LogoComponent from "@/components/logo/LogoComponent";
import { useAuth } from "@/hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { INITIAL_UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION } from "@/graphql/mutations/settingsMutations";
import { GET_EBAY_ACCOUNT_SETTINGS_QUERY } from "@/graphql/queries/ebay/settings/settingsQueries";
import CircularLoader from "@/components/loading/LoadingComponent";

const InitialSettingsPage: React.FC = () => {
    console.log("Executing InitialSettingsPage");
    const { logoutUser, ebayAccount, fetchUserData } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (ebayAccount && ebayAccount.initialSettingsCompleted) {
            navigate("/dashboard");
        }
    }, [ebayAccount, navigate]);

    const {
        data: ebaySettingsData,
        loading: ebaySettingsLoading,
        error: ebaySettingsError,
    } = useQuery(GET_EBAY_ACCOUNT_SETTINGS_QUERY);

    const [updateEbayAccountSettings] = useMutation(
        INITIAL_UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION,
        {
            onCompleted: () => {
                console.log("Settings updated");
            },
        },
    );

    const [matchPostagePriceWithCost, setMatchPostagePriceWithCost] = useState<
        boolean | null
    >(null);

    useEffect(() => {
        if (ebaySettingsData && ebaySettingsData.ebayAccountSettings) {
            setMatchPostagePriceWithCost(
                ebaySettingsData.ebayAccountSettings.matchPostagePriceWithCost,
            );
        }
    }, [ebaySettingsData]);

    const handleSave = async () => {
        try {
            // Await the mutation to complete before navigating
            await updateEbayAccountSettings({
                variables: {
                    matchPostagePriceWithCost,
                },
            });

            // After the settings update is complete, fetch user data
            fetchUserData();

            // Then navigate to the dashboard
            navigate("/dashboard");
        } catch (error) {
            console.error("Error updating settings: ", error);
        }
    };

    if (ebaySettingsLoading) return <CircularLoader />;
    if (ebaySettingsError) return <p>Error: {ebaySettingsError?.message}</p>;

    return (
        <div className="flex min-h-full flex-1 flex-col px-2 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Set Your Account Preferences
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
                    Customize your FlipLytics experience by adjusting your
                    settings below.
                </p>
            </div>
            <div className="mt-8 grid grid-cols-4 gap-4">
                <div className="relative col-span-2 col-start-2 rounded-lg bg-white p-6 shadow-md dark:bg-gray-800">
                    <h2 className="mb-4 text-xl font-semibold dark:text-white">
                        Account Settings
                    </h2>
                    <div className="space-y-4">
                        <div className="flex justify-between border-b border-gray-600 pb-4">
                            <div className="flex w-3/4 flex-col">
                                <span className="font-medium dark:text-gray-400">
                                    Match Postage Costs
                                </span>
                                <span className="text-sm dark:text-gray-400">
                                    If you enable this setting, FlipLytics will
                                    automatically match the costs that you paid
                                    for postage with the costs that you charged
                                    your customers, which we get from eBay
                                    directly.
                                </span>
                            </div>
                            <div className="w-1/4 text-right dark:text-white">
                                <select
                                    value={
                                        matchPostagePriceWithCost ? "Yes" : "No"
                                    }
                                    onChange={(e) =>
                                        setMatchPostagePriceWithCost(
                                            e.target.value === "Yes",
                                        )
                                    }
                                    className="form-select dark:bg-gray-700 dark:text-white"
                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 flex justify-end space-x-2">
                        <button
                            onClick={handleSave}
                            className="rounded-md bg-brand px-4 py-2 text-white"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <div className="sm:w mt-10 sm:mx-auto">
                <p className="text-center text-sm dark:text-gray-400">
                    Need help?{" "}
                    <a
                        href="https://fliplytics.co.uk/contact"
                        className="hover:text-brand-light font-semibold text-brand"
                    >
                        Contact our support team
                    </a>
                    .
                </p>
                <p className="mt-3 text-center text-sm dark:text-gray-400">
                    <a
                        href="#"
                        onClick={logoutUser}
                        className="hover:text-brand-light font-semibold text-brand"
                    >
                        Sign out
                    </a>
                </p>
            </div>
        </div>
    );
};

export default InitialSettingsPage;
