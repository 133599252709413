import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { VERIFY_EMAIL_MUTATION } from "@/graphql/mutations/authMutations";
import LogoComponent from "@/components/logo/LogoComponent";
import { useAuth } from "@/hooks/auth/useAuth";

const VerifyEmailPage = () => {
    const { token } = useParams<{ token: string }>();
    const [verifyEmail, { loading, error }] = useMutation(
        VERIFY_EMAIL_MUTATION,
    );
    const navigate = useNavigate();
    const [message, setMessage] = useState<string | null>(null);

    const { fetchUserData } = useAuth();

    useEffect(() => {
        if (token) {
            verifyEmail({ variables: { token } })
                .then((response) => {
                    if (response.data.verifyEmail.success) {
                        setMessage(
                            "Email verified successfully. Redirecting...",
                        );
                        fetchUserData();
                        setTimeout(() => navigate("/ebay-auth"), 3000);
                    } else {
                        setMessage(response.data.verifyEmail.message);
                        setTimeout(() => navigate("/ebay-auth"), 3000);
                    }
                })
                .catch((err) => {
                    console.error("Error verifying email:", err);
                    setMessage(
                        "An error occurred during verification. Please try again later.",
                    );
                });
        }
    }, [token, verifyEmail, navigate, fetchUserData]);

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Email Verification
                </h2>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                {loading && (
                    <p className="text-center text-sm dark:text-gray-400">
                        Verifying...
                    </p>
                )}
                {message && (
                    <p
                        className={`text-center text-sm ${error ? "text-red-600" : "text-green-600"}`}
                    >
                        {message}
                    </p>
                )}
            </div>
        </div>
    );
};

export default VerifyEmailPage;
