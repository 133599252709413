import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://fcf28179a69c98466df9e2d2e9d19cf7@o4507589315133440.ingest.de.sentry.io/4507589846237264",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/backend\.fliplytics\.co\.uk/, // Your production API
        // Add other domains or patterns as needed
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // Set to 10% in production, can be 100% in development
    replaysOnErrorSampleRate: 1.0, // 100% sampling for sessions with errors
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || "development",
});

export default Sentry;
