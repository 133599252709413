import { gql } from "@apollo/client";

export const CREATE_CHECKOUT_SESSION_MUTATION = gql`
    mutation CreateCheckoutSessionMutation($billingCycle: BillingCycleEnum!) {
        createCheckoutSession(billingCycle: $billingCycle) {
            checkoutUrl
            sessionId
        }
    }
`;

export const CREATE_MANAGE_SUBSCRIPTION_SESSION_MUTATION = gql`
    mutation CreateManageSubscriptionSessionMutation {
        createManageSubscriptionSession {
            customerPortalUrl
        }
    }
`;
