import { gql } from "@apollo/client";

export const GET_ACCOUNT_SETTINGS_QUERY = gql`
    query GetAccountSettingsQuery {
        primaryEbayAccount {
            ebayUserId
            user {
                email
                firstName
                lastName
                dateJoined
            }
            storeType
        }
        subscription {
            currentPeriodEnd
            status
            trialEnd
            stripeSubscriptionId
        }
        tierPricing {
            monthlyPrice
            yearlyPrice
            currency
        }
        totalStats {
            totalActiveListings
            totalEndedListings
            totalProductsSold
        }
        billingCycle
    }
`;
