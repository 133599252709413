import React, { useState, useEffect, useRef } from "react";
import { format, parseISO, startOfDay, endOfDay } from "date-fns";

interface TableFiltersProps {
    /** dateFilterType accepts values "listedAt" and "soldAt" depending on the table the filters are being applied to.
     * Also update initialStartDate and initialEndDate values with "filters.soldAt_Gte"/"filters.soldAt_Lte" */
    dateFilterType: "listedAt" | "soldAt";
    initialStartDate: Date;
    initialEndDate: Date;
    setFilters: (name: string, value: any) => void;
    applyFilters: () => void;
    resetFilters: () => void;
    filters: Record<string, any>;
    showFilters: boolean;
    toggleFilters: () => void;
}

const presets = [
    { text: "Today", start: startOfDay(new Date()), end: endOfDay(new Date()) },
    {
        text: "Yesterday",
        start: startOfDay(
            new Date(new Date().setDate(new Date().getDate() - 1)),
        ),
        end: endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
    },
    {
        text: "Last 7 Days",
        start: startOfDay(
            new Date(new Date().setDate(new Date().getDate() - 7)),
        ),
        end: endOfDay(new Date()),
    },
    {
        text: "Last 30 Days",
        start: startOfDay(
            new Date(new Date().setDate(new Date().getDate() - 30)),
        ),
        end: endOfDay(new Date()),
    },
    {
        text: "Last 3 Months",
        start: startOfDay(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
        ),
        end: endOfDay(new Date()),
    },
    {
        text: "Last 6 Months",
        start: startOfDay(
            new Date(new Date().setMonth(new Date().getMonth() - 6)),
        ),
        end: endOfDay(new Date()),
    },
    {
        text: "This Month",
        start: startOfDay(new Date(new Date().setDate(1))),
        end: endOfDay(new Date()),
    },
    {
        text: "Last Month",
        start: startOfDay(
            new Date(new Date().setMonth(new Date().getMonth() - 1, 1)),
        ),
        end: endOfDay(new Date(new Date().setMonth(new Date().getMonth(), 0))),
    },
    {
        text: "This Year",
        start: startOfDay(new Date(new Date().setMonth(0, 1))),
        end: endOfDay(new Date()),
    },
    {
        text: "Last Year",
        start: startOfDay(
            new Date(
                new Date().setFullYear(new Date().getFullYear() - 1, 0, 1),
            ),
        ),
        end: endOfDay(
            new Date(
                new Date().setFullYear(new Date().getFullYear() - 1, 11, 31),
            ),
        ),
    },
    {
        text: "Last 5 Years",
        start: startOfDay(
            new Date(new Date().setFullYear(new Date().getFullYear() - 5)),
        ),
        end: endOfDay(new Date()),
    },
    {
        text: "All Time",
        start: startOfDay(new Date(1970, 0, 1)),
        end: endOfDay(new Date()),
    },
];

const TableFilters: React.FC<TableFiltersProps> = ({
    dateFilterType = "listedAt",
    initialStartDate,
    initialEndDate,
    setFilters,
    applyFilters,
    resetFilters,
    showFilters,
    toggleFilters,
}) => {
    const [startDate, setStartDate] = useState<Date>(initialStartDate);
    const [endDate, setEndDate] = useState<Date>(initialEndDate);
    const [tempStartDate, setTempStartDate] = useState<string>(
        format(initialStartDate, "yyyy-MM-dd"),
    );
    const [tempEndDate, setTempEndDate] = useState<string>(
        format(initialEndDate, "yyyy-MM-dd"),
    );
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const handleApplyClick = () => {
        const parsedStartDate = parseISO(tempStartDate);
        const parsedEndDate = parseISO(tempEndDate);

        const formattedStartDate = startOfDay(parsedStartDate).toISOString();
        const formattedEndDate = endOfDay(parsedEndDate).toISOString();

        setStartDate(parsedStartDate);
        setEndDate(parsedEndDate);
        setFilters(`${dateFilterType}_Gte`, formattedStartDate);
        setFilters(`${dateFilterType}_Lte`, formattedEndDate);
        applyFilters();
        setIsOpen(false);
    };

    const handlePresetClick = (preset: { start: Date; end: Date }) => {
        setTempStartDate(format(preset.start, "yyyy-MM-dd"));
        setTempEndDate(format(preset.end, "yyyy-MM-dd"));
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    const handleBlur = (
        e: React.FocusEvent<HTMLInputElement>,
        setDate: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const parsedDate = parseISO(e.target.value);
        if (!isNaN(parsedDate.getTime())) {
            setDate(e.target.value);
        } else {
            setDate(format(new Date(), "yyyy-MM-dd"));
        }
    };

    const handleResetFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        resetFilters();
        setStartDate(initialStartDate);
        setEndDate(initialEndDate);
        setTempStartDate(format(initialStartDate, "yyyy-MM-dd"));
        setTempEndDate(format(initialEndDate, "yyyy-MM-dd"));
    };

    useEffect(() => {
        // Update state when initialStartDate or initialEndDate props change to update the date range picker
        setStartDate(initialStartDate);
        setEndDate(initialEndDate);
        setTempStartDate(format(initialStartDate, "yyyy-MM-dd"));
        setTempEndDate(format(initialEndDate, "yyyy-MM-dd"));
    }, [initialStartDate, initialEndDate]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div>
            <div className="relative inline-block w-full" ref={ref}>
                <div className="flex items-center justify-between">
                    <button
                        className="inline-flex items-center justify-between rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ minWidth: "250px" }}
                    >
                        {format(startDate, "dd/MM/yyyy")} -{" "}
                        {format(endDate, "dd/MM/yyyy")}
                        <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </button>

                    <div className="ml-4 flex space-x-2">
                        <button
                            onClick={handleResetFilters}
                            className="rounded-lg bg-gray-600 px-4 py-2 text-white dark:bg-gray-300 dark:text-gray-900"
                        >
                            Reset Filters
                        </button>
                        <button
                            onClick={toggleFilters}
                            className="rounded-lg border border-black bg-white px-4 py-2 text-black dark:border-white dark:bg-gray-700 dark:text-white"
                        >
                            {showFilters ? "Hide Filters" : "Show Filters"}
                        </button>
                    </div>
                </div>

                {isOpen && (
                    <div className="fixed z-50 mt-2 w-80 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-900">
                        <div className="px-4 py-2">
                            <div className="date-picker mb-4 grid grid-cols-2 gap-2">
                                <div className="flex flex-col">
                                    <label className="mb-1 text-gray-700 dark:text-gray-300">
                                        Start Date
                                    </label>
                                    <input
                                        type="date"
                                        value={tempStartDate}
                                        onChange={(e) =>
                                            setTempStartDate(e.target.value)
                                        }
                                        onBlur={(e) =>
                                            handleBlur(e, setTempStartDate)
                                        }
                                        className="rounded-lg border p-2 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label className="mb-1 text-gray-700 dark:text-gray-300">
                                        End Date
                                    </label>
                                    <input
                                        type="date"
                                        value={tempEndDate}
                                        onChange={(e) =>
                                            setTempEndDate(e.target.value)
                                        }
                                        onBlur={(e) =>
                                            handleBlur(e, setTempEndDate)
                                        }
                                        className="rounded-lg border p-2 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
                                    />
                                </div>
                            </div>
                            <div className="presets mb-4 grid grid-cols-2 gap-2">
                                {presets.map((preset) => (
                                    <button
                                        key={preset.text}
                                        onClick={() =>
                                            handlePresetClick(preset)
                                        }
                                        className="bg-blue-500 hover:bg-blue-600 rounded-lg px-4 py-2 text-gray-700 transition dark:text-white"
                                    >
                                        {preset.text}
                                    </button>
                                ))}
                            </div>
                            <div className="flex justify-end">
                                <button
                                    onClick={handleApplyClick}
                                    className="rounded-lg bg-green-500 px-4 py-2 text-white transition hover:bg-green-600"
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TableFilters;
