import LogoComponent from "@/components/logo/LogoComponent";
import { INITIATE_EBAY_AUTH_MUTATION } from "@/graphql/mutations/oauthMutations";
import { useMutation } from "@apollo/client/react";
import { useAuth } from "@/hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function EbayAuthPage() {
    const [initiateEbayAuth] = useMutation(INITIATE_EBAY_AUTH_MUTATION);
    const { ebayAccount } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (ebayAccount && ebayAccount.isActive) {
            navigate("/dashboard");
        }
    }, [ebayAccount, navigate]);

    const initiateOauth = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const { data } = await initiateEbayAuth();

            if (data.initiateOauth.loginUrl) {
                window.location.href = data.initiateOauth.loginUrl;
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    To use this application, you must first authenticate with
                    eBay. Click the button below to get started.
                </h2>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={initiateOauth}>
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md border border-transparent bg-brand px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand/80 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                            >
                                Authenticate with eBay
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
