import { useQuery, useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import { GET_ACCOUNT_SETTINGS_QUERY } from "@/graphql/queries/accountsQueries";
import { GET_EBAY_ACCOUNT_SETTINGS_QUERY } from "@/graphql/queries/ebay/settings/settingsQueries";
import {
    UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION,
    UPDATE_USER_MUTATION,
} from "@/graphql/mutations/settingsMutations";
import { CREATE_MANAGE_SUBSCRIPTION_SESSION_MUTATION } from "@/graphql/mutations/paymentMutations";
import InitialsComponent from "@/components/dashboard/general/svgs/InitialsComponent";
import CircularLoader from "@/components/loading/LoadingComponent";
import PasswordUpdateModal from "@/components/dashboard/dash/account/modals/PasswordUpdateModal";
import { useNavigate } from "react-router-dom";

export default function AccountSettingsPage() {
    const navigate = useNavigate();
    const { data, loading, error } = useQuery(GET_ACCOUNT_SETTINGS_QUERY);
    const {
        data: ebaySettingsData,
        loading: ebaySettingsLoading,
        error: ebaySettingsError,
    } = useQuery(GET_EBAY_ACCOUNT_SETTINGS_QUERY);

    const [updateEbayAccountSettings] = useMutation(
        UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION,
        {
            onCompleted: () => {
                setEditMode(false);
            },
        },
    );

    const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
        onCompleted: () => {
            setEditAccountMode(false);
        },
    });

    const [createManageSubscriptionSession] = useMutation(
        CREATE_MANAGE_SUBSCRIPTION_SESSION_MUTATION,
        {
            onCompleted: (data) => {
                const portalUrl =
                    data.createManageSubscriptionSession.customerPortalUrl;
                if (portalUrl) {
                    window.location.href = portalUrl;
                }
            },
            onError: (error) => {
                console.error("Error creating subscription session:", error);
            },
        },
    );

    const [editMode, setEditMode] = useState(false);
    const [editAccountMode, setEditAccountMode] = useState(false); // State for editing account details
    const [passwordModalOpen, setPasswordModalOpen] = useState(false); // State for password modal
    const [firstName, setFirstName] = useState<string>(""); // First name state
    const [lastName, setLastName] = useState<string>(""); // Last name state
    const [email, setEmail] = useState<string>(""); // Email state
    const [matchPostagePriceWithCost, setMatchPostagePriceWithCost] = useState<
        boolean | null
    >(null);

    useEffect(() => {
        if (ebaySettingsData && ebaySettingsData.ebayAccountSettings) {
            setMatchPostagePriceWithCost(
                ebaySettingsData.ebayAccountSettings.matchPostagePriceWithCost,
            );
        }
        if (data && data.primaryEbayAccount) {
            const { user } = data.primaryEbayAccount;
            setFirstName(user.firstName); // Set first name
            setLastName(user.lastName); // Set last name
            setEmail(user.email); // Set email
        }
    }, [ebaySettingsData, data]);

    if (loading || ebaySettingsLoading) return <CircularLoader />;
    if (error || ebaySettingsError)
        return <p>Error: {error?.message || ebaySettingsError?.message}</p>;

    const {
        primaryEbayAccount,
        subscription,
        tierPricing,
        totalStats,
        billingCycle,
    } = data;

    const { ebayUserId: storeName, user } = primaryEbayAccount;
    const { dateJoined } = user;

    const name = `${firstName} ${lastName}`;
    const dateSignedUp = new Date(dateJoined).toLocaleDateString();
    const storeUrl = "https://www.ebay.co.uk/usr/" + storeName;

    const price =
        billingCycle === "MONTHLY"
            ? tierPricing.monthlyPrice
            : tierPricing.yearlyPrice;
    const currency_name = tierPricing.currency;
    const currencySymbol = (currency: string) => {
        switch (currency.toUpperCase()) {
            case "USD":
                return "$";
            case "EUR":
                return "€";
            case "GBP":
                return "£";
            default:
                return currency;
        }
    };

    const price_suffix = billingCycle === "MONTHLY" ? "pm" : "py";
    const fullPrice = `${currencySymbol(currency_name)}${price}${price_suffix}`;

    const stats = [
        {
            name: "Total Active Listings",
            value: totalStats.totalActiveListings,
        },
        {
            name: "Total Ended Listings",
            value: totalStats.totalEndedListings,
        },
        {
            name: "Total Products Sold",
            value: totalStats.totalProductsSold,
        },
    ];

    const handleSettingsEdit = () => {
        setEditMode(true);
    };

    const handleAccountDetailsEdit = () => {
        setEditAccountMode(true); // Trigger edit mode for account details
    };

    const handleAccountSave = () => {
        // Save account details function
        updateUser({
            variables: {
                email,
                firstName,
                lastName,
            },
        });
        navigate(0); // Refresh the page to reflect changes / redirect to email verification page if email was changed
    };

    const handleCancelAccountEdit = () => {
        setEditAccountMode(false); // Cancel edit mode
    };

    const handleCancel = () => {
        setEditMode(false);
    };

    const handleSave = () => {
        updateEbayAccountSettings({
            variables: {
                matchPostagePriceWithCost,
            },
        });
    };

    const changePassword = () => {
        setPasswordModalOpen(true); // Open password modal
    };

    const handleManageSubscription = (e: React.MouseEvent) => {
        e.preventDefault(); // Prevent page refresh
        createManageSubscriptionSession();
    };

    const subscriptionStatusDetails =
        subscription.status === "active" ? (
            <>
                <span className="dark:text-gray-400">Next Payment</span>
                <span className="dark:text-white">
                    {new Date(
                        subscription.currentPeriodEnd,
                    ).toLocaleDateString()}
                </span>
            </>
        ) : subscription.status === "trialing" ? (
            <>
                <span className="dark:text-gray-400">Trial Ends</span>
                <span className="dark:text-white">
                    {new Date(subscription.trialEnd).toLocaleDateString()}
                </span>
            </>
        ) : null;

    return (
        <div className="min-h-screen py-6 dark:bg-gray-900">
            <div className="container px-4">
                <h3 className="mb-4 text-base font-semibold leading-6 text-gray-900 dark:text-white">
                    Your FlipLytics Account
                </h3>
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-4">
                    <div className="relative col-span-4 rounded-lg bg-white p-6 shadow-md dark:bg-gray-800 lg:col-span-2">
                        <button
                            onClick={handleAccountDetailsEdit} // Edit account details button
                            className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-5 w-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.862 3.487a1.125 1.125 0 011.588 0l2.062 2.062a1.125 1.125 0 010 1.588L7.125 20.525l-4.5.75.75-4.5L16.862 3.487z"
                                />
                            </svg>
                        </button>
                        <div className="mb-6 flex items-center">
                            <InitialsComponent
                                name={name}
                                className="flex items-center justify-center rounded-full"
                            />
                            <h1 className="ml-4 text-3xl font-semibold dark:text-white">
                                {name}
                            </h1>
                        </div>

                        <div className="space-y-4">
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Email
                                </span>
                                {editAccountMode ? ( // Show input fields when editing
                                    <div className="relative w-3/4">
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            className="form-input w-full dark:bg-gray-700 dark:text-white"
                                        />
                                    </div>
                                ) : (
                                    <span className="dark:text-white">
                                        {email}
                                    </span>
                                )}
                            </div>

                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    First Name
                                </span>
                                {editAccountMode ? (
                                    <input
                                        type="text"
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                        className="form-input dark:bg-gray-700 dark:text-white"
                                    />
                                ) : (
                                    <span className="dark:text-white">
                                        {firstName}
                                    </span>
                                )}
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Last Name
                                </span>
                                {editAccountMode ? (
                                    <input
                                        type="text"
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                        className="form-input dark:bg-gray-700 dark:text-white"
                                    />
                                ) : (
                                    <span className="dark:text-white">
                                        {lastName}
                                    </span>
                                )}
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    eBay user ID
                                </span>
                                <span className="text-brand">
                                    <a
                                        href={storeUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {storeName}
                                    </a>
                                </span>
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Password
                                </span>
                                <button
                                    onClick={changePassword} // Open password modal
                                    className="text-brand"
                                >
                                    Change your password &raquo;
                                </button>
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Joined FlipLytics
                                </span>
                                <span className="dark:text-white">
                                    {dateSignedUp}
                                </span>
                            </div>
                        </div>

                        {editAccountMode && ( // Show save and cancel buttons if in edit mode
                            <div className="mt-4 flex justify-end space-x-2">
                                <button
                                    onClick={handleCancelAccountEdit}
                                    className="rounded-md bg-gray-300 px-4 py-2 text-gray-700 dark:bg-gray-600 dark:text-gray-300"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleAccountSave} // Call save function
                                    className="bg-blue-600 dark:bg-blue-500 rounded-md px-4 py-2 text-white"
                                >
                                    Save
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="col-span-4 rounded-lg bg-white p-6 shadow-md dark:bg-gray-800 lg:col-span-2">
                        <h2 className="mb-4 text-xl font-semibold dark:text-white">
                            Subscription Details
                        </h2>
                        <div className="space-y-4">
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">Plan</span>
                                <span className="dark:text-white">
                                    {primaryEbayAccount.storeType
                                        .charAt(0)
                                        .toUpperCase() +
                                        primaryEbayAccount.storeType
                                            .slice(1)
                                            .toLowerCase()}
                                </span>
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Status
                                </span>
                                <span className="dark:text-white">
                                    {subscription.status
                                        .charAt(0)
                                        .toUpperCase() +
                                        subscription.status
                                            .slice(1)
                                            .toLowerCase()}
                                </span>
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                {subscriptionStatusDetails}
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Price
                                </span>
                                <span className="dark:text-white">
                                    {fullPrice}
                                </span>
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <button
                                    onClick={handleManageSubscription}
                                    className="text-brand"
                                    type="button"
                                >
                                    Manage Subscription
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="relative col-span-4 rounded-lg bg-white p-6 shadow-md dark:bg-gray-800 lg:col-span-2">
                        {!editMode && (
                            <button
                                onClick={handleSettingsEdit}
                                className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-5 w-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.862 3.487a1.125 1.125 0 011.588 0l2.062 2.062a1.125 1.125 0 010 1.588L7.125 20.525l-4.5.75.75-4.5L16.862 3.487z"
                                    />
                                </svg>
                            </button>
                        )}
                        <h2 className="mb-4 text-xl font-semibold dark:text-white">
                            Account Settings
                        </h2>
                        <div className="space-y-4">
                            <div className="flex justify-between border-b border-gray-600 pb-4">
                                <div className="flex w-3/4 flex-col">
                                    <span className="font-medium dark:text-gray-400">
                                        Match Postage Costs
                                    </span>
                                    <span className="text-sm dark:text-gray-400">
                                        If you enable this setting, FlipLytics
                                        will automatically match the costs that
                                        you paid for postage with the costs that
                                        you charged your customers which we get
                                        from eBay directly.
                                    </span>
                                </div>
                                <div className="w-1/4 text-right dark:text-white">
                                    {editMode ? (
                                        <select
                                            value={
                                                matchPostagePriceWithCost
                                                    ? "Yes"
                                                    : "No"
                                            }
                                            onChange={(e) =>
                                                setMatchPostagePriceWithCost(
                                                    e.target.value === "Yes",
                                                )
                                            }
                                            className="form-select dark:bg-gray-700 dark:text-white"
                                        >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    ) : matchPostagePriceWithCost !== null ? (
                                        matchPostagePriceWithCost ? (
                                            "Yes"
                                        ) : (
                                            "No"
                                        )
                                    ) : (
                                        <CircularLoader />
                                    )}
                                </div>
                            </div>
                        </div>
                        {editMode && (
                            <div className="mt-4 flex justify-end space-x-2">
                                <button
                                    onClick={handleCancel}
                                    className="rounded-md bg-gray-300 px-4 py-2 text-gray-700 dark:bg-gray-600 dark:text-gray-300"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="bg-blue-600 dark:bg-blue-500 rounded-md px-4 py-2 text-white"
                                >
                                    Save
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="col-span-4 rounded-lg bg-white p-6 shadow-md dark:bg-gray-800 lg:col-span-2">
                        <h2 className="mb-4 text-xl font-semibold dark:text-white">
                            Your Stats
                        </h2>
                        <div className="space-y-4">
                            {stats.map((stat, index) => (
                                <div
                                    key={index}
                                    className="flex justify-between border-b border-gray-600 pb-2"
                                >
                                    <span className="dark:text-gray-400">
                                        {stat.name}
                                    </span>
                                    <span className="dark:text-white">
                                        {stat.value}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <PasswordUpdateModal
                        isOpen={passwordModalOpen}
                        closeModal={() => setPasswordModalOpen(false)}
                    />
                </div>
            </div>
        </div>
    );
}
