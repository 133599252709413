import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_UNSOLD_EBAY_ITEMS_QUERY } from "@/graphql/queries/ebay/inventory/ebayInventoryQueries";
import { subDays, startOfToday, endOfToday } from "date-fns";

const useUnsoldEbayItems = () => {
    const initialFilters = {
        tags: null,
        sourceLocationId: null,
        listedPriceLte: null,
        listedPriceGte: null,
        itemCategoryId: null,
        itemBrandId: null,
        listedAt_Gte: subDays(startOfToday(), 30).toISOString(),
        listedAt_Lte: endOfToday().toISOString(),
    };

    const [filters, setFilters] = useState(initialFilters);
    const [pagination, setPagination] = useState({
        after: null,
        before: null,
    });

    const { data, loading, error, refetch } = useQuery(
        GET_UNSOLD_EBAY_ITEMS_QUERY,
        {
            variables: { ...filters, ...pagination },
        },
    );

    const handleFilterChange = (name: string, value: any) => {
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const applyFilters = () => {
        refetch({ ...filters, ...pagination });
    };

    const handleNextPage = () => {
        if (data?.unsoldEbayItems?.pageInfo?.hasNextPage) {
            setPagination((prev) => ({
                ...prev,
                after: data.unsoldEbayItems.pageInfo.endCursor,
            }));
            applyFilters();
        }
    };

    const handlePreviousPage = () => {
        if (data?.unsoldEbayItems?.pageInfo?.hasPreviousPage) {
            setPagination((prev) => ({
                ...prev,
                before: data.unsoldEbayItems.pageInfo.startCursor,
            }));
            applyFilters();
        }
    };

    const resetFilters = () => {
        setFilters(initialFilters);
        refetch({ ...initialFilters, ...pagination });
    };

    return {
        data,
        loading,
        error,
        filters,
        setFilters: handleFilterChange,
        applyFilters,
        handleNextPage,
        handlePreviousPage,
        pageInfo: data?.unsoldEbayItems?.pageInfo || {},
        resetFilters,
    };
};

export default useUnsoldEbayItems;
