import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
    GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY,
    GET_EBAY_ITEM_BRANDS_QUERY,
    GET_EBAY_ITEM_CATEGORIES_QUERY,
    GET_EBAY_ITEM_TAGS_QUERY,
} from "@/graphql/queries/ebay/general/ebayGeneralQueries";
import useMissingDataEbaySales from "@/hooks/sold/useMissingDataEbaySales";
import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";
import { TableComponentProps } from "@/types/globalTypes";
import IconComponent from "@/components/icon/IconComponent";
import BulkEditEbayItemsModal from "./modals/BulkEditEbayItemsModal";
import EditSaleModal from "../products/modals/EditSaleModal";
import CircularLoader from "@/components/loading/LoadingComponent";
import TableFilters from "../dash/widgets/TableFiltersComponent";

const MissingDataEbaySalesTableComponent: React.FC<TableComponentProps> = ({
    columns,
}) => {
    const {
        data,
        loading,
        error,
        filters,
        setFilters,
        handleNextPage,
        handlePreviousPage,
        pageInfo,
        resetFilters,
        applyFilters,
    } = useMissingDataEbaySales();

    const { data: tagsData } = useQuery(GET_EBAY_ITEM_TAGS_QUERY);
    const { data: categoriesData } = useQuery(GET_EBAY_ITEM_CATEGORIES_QUERY);
    const { data: brandsData } = useQuery(GET_EBAY_ITEM_BRANDS_QUERY);
    const { data: sourceLocationsData } = useQuery(
        GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY,
    );

    const [showFilters, setShowFilters] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [isBulkEditModalOpen, setBulkEditModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [currentSale, setCurrentSale] = useState<any>(null); // State to hold the sale being edited

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = e.target;
        setFilters(name, value === "" ? null : value);
    };

    const toggleFilters = () => {
        setShowFilters((prevShowFilters) => !prevShowFilters);
    };

    const handleCheckboxChange = (id: string) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(id)
                ? prevSelectedItems.filter((itemId) => itemId !== id)
                : [...prevSelectedItems, id],
        );
    };

    const openBulkEditModal = () => {
        setBulkEditModalOpen(true);
    };

    const closeBulkEditModal = () => {
        setBulkEditModalOpen(false);
    };

    const openEditModal = (sale: any) => {
        setCurrentSale(sale);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
        setCurrentSale(null);
    };

    if (loading) return <CircularLoader />;
    if (error) return <p>Error: {error.message}</p>;

    const sales = data?.missingDataEbaySales?.edges || [];

    return (
        <div className="h-full dark:bg-gray-900">
            <div className="py-2 dark:bg-gray-900">
                <div className="px-2 sm:px-2 lg:px-1">
                    <div className="flow-root">
                        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                {/* Add overflow-x-auto for horizontal scroll */}
                                <div className="max-h-[calc(100vh-10rem)] overflow-x-auto overflow-y-hidden">
                                    <div className="max-h-[calc(100vh-10rem)] overflow-y-auto">
                                        <table className="min-w-full table-fixed divide-y dark:divide-gray-700">
                                            <thead className="sticky top-0 z-10 dark:bg-gray-900">
                                                <tr>
                                                    <th
                                                        colSpan={
                                                            columns.length + 2
                                                        }
                                                    >
                                                        <div className="flex items-center justify-between px-2 pb-2 sm:px-2 lg:px-1">
                                                            <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                                eBay Sales
                                                            </h3>
                                                            <div className="flex space-x-2">
                                                                <TableFilters
                                                                    dateFilterType="soldAt"
                                                                    initialStartDate={
                                                                        new Date(
                                                                            filters.soldAt_Gte,
                                                                        )
                                                                    }
                                                                    initialEndDate={
                                                                        new Date(
                                                                            filters.soldAt_Lte,
                                                                        )
                                                                    }
                                                                    setFilters={
                                                                        setFilters
                                                                    }
                                                                    applyFilters={
                                                                        applyFilters
                                                                    }
                                                                    resetFilters={
                                                                        resetFilters
                                                                    }
                                                                    filters={
                                                                        filters
                                                                    }
                                                                    showFilters={
                                                                        showFilters
                                                                    }
                                                                    toggleFilters={
                                                                        toggleFilters
                                                                    }
                                                                />
                                                                <button
                                                                    onClick={
                                                                        openBulkEditModal
                                                                    }
                                                                    disabled={
                                                                        selectedItems.length ===
                                                                        0
                                                                    }
                                                                    className={`px-4 py-2 ${
                                                                        selectedItems.length >
                                                                        0
                                                                            ? "bg-blue-600 text-white"
                                                                            : "bg-gray-400 text-gray-600"
                                                                    } rounded-lg`}
                                                                >
                                                                    Bulk Edit
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {showFilters && (
                                                            <div className="mt-4 px-2 pb-4 sm:px-2 lg:px-1">
                                                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                                                                    <select
                                                                        name="ebayItem_Tags_Id"
                                                                        value={
                                                                            filters.ebayItem_Tags_Id ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded border px-4 py-2 dark:bg-gray-700 dark:text-white"
                                                                    >
                                                                        <option value="">
                                                                            All
                                                                            Tags
                                                                        </option>
                                                                        {tagsData?.ebayItemTags
                                                                            .slice()
                                                                            .sort(
                                                                                (
                                                                                    a: any,
                                                                                    b: any,
                                                                                ) =>
                                                                                    a.name.localeCompare(
                                                                                        b.name,
                                                                                    ),
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    tag: any,
                                                                                ) => (
                                                                                    <option
                                                                                        key={
                                                                                            tag.id
                                                                                        }
                                                                                        value={
                                                                                            tag.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            tag.name
                                                                                        }
                                                                                    </option>
                                                                                ),
                                                                            )}
                                                                    </select>
                                                                    <select
                                                                        name="ebayItem_SourceLocation_Id"
                                                                        value={
                                                                            filters.ebayItem_SourceLocation_Id ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded border px-4 py-2 dark:bg-gray-700 dark:text-white"
                                                                    >
                                                                        <option value="">
                                                                            All
                                                                            Source
                                                                            Locations
                                                                        </option>
                                                                        {sourceLocationsData?.sourceLocations
                                                                            .slice()
                                                                            .sort(
                                                                                (
                                                                                    a: any,
                                                                                    b: any,
                                                                                ) =>
                                                                                    a.name.localeCompare(
                                                                                        b.name,
                                                                                    ),
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    location: any,
                                                                                ) => (
                                                                                    <option
                                                                                        key={
                                                                                            location.id
                                                                                        }
                                                                                        value={
                                                                                            location.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            location.name
                                                                                        }
                                                                                    </option>
                                                                                ),
                                                                            )}
                                                                    </select>
                                                                    <input
                                                                        type="number"
                                                                        name="ebayItem_ListedPrice_Lte"
                                                                        placeholder="Max Listed Price"
                                                                        defaultValue={
                                                                            filters.ebayItem_ListedPrice_Lte ||
                                                                            ""
                                                                        }
                                                                        onBlur={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded border px-4 py-2 dark:bg-gray-700 dark:text-white"
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="ebayItem_ListedPrice_Gte"
                                                                        placeholder="Min Listed Price"
                                                                        defaultValue={
                                                                            filters.ebayItem_ListedPrice_Gte ||
                                                                            ""
                                                                        }
                                                                        onBlur={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded border px-4 py-2 dark:bg-gray-700 dark:text-white"
                                                                    />
                                                                    <select
                                                                        name="ebayItem_ItemCategory_Id"
                                                                        value={
                                                                            filters.ebayItem_ItemCategory_Id ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded border px-4 py-2 dark:bg-gray-700 dark:text-white"
                                                                    >
                                                                        <option value="">
                                                                            All
                                                                            Categories
                                                                        </option>
                                                                        {categoriesData?.itemCategories
                                                                            .slice()
                                                                            .sort(
                                                                                (
                                                                                    a: any,
                                                                                    b: any,
                                                                                ) =>
                                                                                    a.categoryName.localeCompare(
                                                                                        b.categoryName,
                                                                                    ),
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    category: any,
                                                                                ) => (
                                                                                    <option
                                                                                        key={
                                                                                            category.id
                                                                                        }
                                                                                        value={
                                                                                            category.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            category.categoryName
                                                                                        }
                                                                                    </option>
                                                                                ),
                                                                            )}
                                                                    </select>
                                                                    <select
                                                                        name="ebayItem_ItemBrand_Id"
                                                                        value={
                                                                            filters.ebayItem_ItemBrand_Id ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded border px-4 py-2 dark:bg-gray-700 dark:text-white"
                                                                    >
                                                                        <option value="">
                                                                            All
                                                                            Brands
                                                                        </option>
                                                                        {brandsData?.brands
                                                                            .slice()
                                                                            .sort(
                                                                                (
                                                                                    a: any,
                                                                                    b: any,
                                                                                ) =>
                                                                                    a.name.localeCompare(
                                                                                        b.name,
                                                                                    ),
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    brand: any,
                                                                                ) => (
                                                                                    <option
                                                                                        key={
                                                                                            brand.id
                                                                                        }
                                                                                        value={
                                                                                            brand.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            brand.name
                                                                                        }
                                                                                    </option>
                                                                                ),
                                                                            )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    {/* Render Checkbox Column Header */}
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                const isChecked =
                                                                    e.target
                                                                        .checked;
                                                                if (isChecked) {
                                                                    // Select all items
                                                                    const allItemIds =
                                                                        sales.map(
                                                                            ({
                                                                                node,
                                                                            }: any) =>
                                                                                decodeBase64Id(
                                                                                    node
                                                                                        .ebayItem
                                                                                        .id,
                                                                                ),
                                                                        );
                                                                    setSelectedItems(
                                                                        allItemIds,
                                                                    );
                                                                } else {
                                                                    // Deselect all items
                                                                    setSelectedItems(
                                                                        [],
                                                                    );
                                                                }
                                                            }}
                                                            checked={
                                                                sales.length >
                                                                    0 &&
                                                                selectedItems.length ===
                                                                    sales.length
                                                            }
                                                        />
                                                    </th>
                                                    {columns.map((col) => (
                                                        <th
                                                            key={col.key}
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                                        >
                                                            {col.label}
                                                        </th>
                                                    ))}
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                                    >
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y dark:divide-gray-800">
                                                {sales.map(({ node }: any) => {
                                                    if (!node) return null;
                                                    const saleItemId =
                                                        decodeBase64Id(
                                                            node.ebayItem.id,
                                                        );
                                                    return (
                                                        <tr key={node.id}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedItems.includes(
                                                                        saleItemId,
                                                                    )}
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            saleItemId,
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                            {columns.map(
                                                                (col) => (
                                                                    <td
                                                                        key={
                                                                            col.key
                                                                        }
                                                                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0"
                                                                    >
                                                                        {col.render
                                                                            ? col.render(
                                                                                  node[
                                                                                      col
                                                                                          .key
                                                                                  ],
                                                                                  node,
                                                                              )
                                                                            : (node[
                                                                                  col
                                                                                      .key
                                                                              ] ??
                                                                              "")}
                                                                    </td>
                                                                ),
                                                            )}
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0">
                                                                <button
                                                                    onClick={() =>
                                                                        openEditModal(
                                                                            node,
                                                                        )
                                                                    }
                                                                    className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-500"
                                                                >
                                                                    <IconComponent
                                                                        iconName="pencil-outline"
                                                                        className="h-5 w-5"
                                                                    />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button
                                        onClick={handlePreviousPage}
                                        disabled={!pageInfo.hasPreviousPage}
                                        className="mr-2 rounded bg-gray-300 px-4 py-2 text-gray-900"
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={!pageInfo.hasNextPage}
                                        className="rounded bg-gray-300 px-4 py-2 text-gray-900"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BulkEditEbayItemsModal
                    isOpen={isBulkEditModalOpen}
                    closeModal={closeBulkEditModal}
                    selectedProducts={selectedItems}
                />
                <EditSaleModal
                    isOpen={isEditModalOpen}
                    closeModal={closeEditModal}
                    sale={currentSale}
                />
            </div>
        </div>
    );
};

export default MissingDataEbaySalesTableComponent;
