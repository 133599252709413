"use client";
import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { toggleFullScreen } from "@/helpers/common/toggleFullScreen";
import {
    Bars3Icon,
    BellIcon,
    ViewfinderCircleIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import IconComponent from "@/components/icon/IconComponent";
import InitialsComponent from "@/components/dashboard/general/svgs/InitialsComponent";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@/hooks/general/useTheme";
import { useAuth } from "@/hooks/auth/useAuth";
import { classNames } from "@/helpers/common/classNames";

interface TopNavProps {
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TopNav: React.FC<TopNavProps> = ({ setSidebarOpen }) => {
    const { toggleTheme } = useTheme();
    const { user, logoutUser } = useAuth();
    const userName = user?.firstName + " " + user?.lastName || "User";
    const myAccountLink = `/dashboard/account`;

    const [currentPage, setCurrentPage] = useState("/dashboard");
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Only update currentPage if "search" is NOT in the URL
        if (!location.pathname.includes("/search")) {
            setCurrentPage(location.pathname);
        }
    }, [location]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        navigate(`/dashboard/search?query=${searchTerm}`); // Using useNavigate to push search query
    };

    const handleClearSearch = () => {
        setSearchTerm("");
        navigate(currentPage);
    };

    return (
        <>
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 bg-white px-4 shadow-sm dark:bg-gray-800 sm:gap-x-6 sm:px-6 lg:pl-0 lg:pr-8">
                <button
                    type="button"
                    className="-m-2.5 p-2.5 dark:text-white lg:hidden"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <form
                        className="relative my-auto flex h-10 flex-1 items-center rounded-full bg-gray-200 dark:bg-gray-700"
                        onSubmit={handleSearchSubmit}
                    >
                        <IconComponent
                            iconName="magnifying-glass-solid-20"
                            className="pointer-events-none absolute left-3 h-full w-5 dark:text-white"
                            aria-hidden="true"
                        />
                        <input
                            id="search-field"
                            className="block w-full rounded-full border-0 bg-gray-200 py-1.5 pl-10 pr-10 focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-white sm:text-sm"
                            placeholder="Search..."
                            type="text"
                            value={searchTerm}
                            onChange={handleSearch}
                            autoComplete="off"
                        />
                        {searchTerm && (
                            <button
                                type="button"
                                onClick={handleClearSearch}
                                className="absolute right-3 h-full w-5 focus:outline-none"
                            >
                                <span className="sr-only">Clear search</span>
                                <XMarkIcon
                                    className="h-5 w-5 dark:text-white"
                                    aria-hidden="true"
                                />
                            </button>
                        )}
                    </form>

                    <div className="flex items-center gap-x-4 lg:gap-x-6">
                        <button
                            type="button"
                            className="-m-2.5 p-2.5 dark:text-white dark:hover:text-white"
                        >
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        <button
                            type="button"
                            onClick={toggleFullScreen}
                            className="-m-2.5 hidden p-2.5 dark:text-white dark:hover:text-white md:block"
                        >
                            <span className="sr-only">Full Screen</span>
                            <ViewfinderCircleIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>

                        {/* Theme toggler */}
                        <button
                            type="button"
                            className="-m-2.5 p-2.5 dark:text-white dark:hover:text-white"
                            onClick={() => toggleTheme()}
                        >
                            <span className="sr-only">Toggle theme</span>
                            <IconComponent
                                iconName="light-bulb-solid"
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>

                        {/* Profile dropdown */}
                        <Menu as="div" className="relative">
                            <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                <span className="sr-only">Open user menu</span>
                                <InitialsComponent name={userName} />
                                <span className="hidden lg:flex lg:items-center">
                                    <span
                                        className="ml-4 text-sm font-semibold leading-6 dark:text-white"
                                        aria-hidden="true"
                                    >
                                        {userName}
                                    </span>
                                    <IconComponent
                                        iconName="chevron-down-solid-20"
                                        className="ml-2 h-5 w-5 dark:text-white"
                                        aria-hidden={true}
                                    />
                                </span>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute -right-3.5 z-10 mt-2.5 w-44 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-brand focus:outline-none dark:bg-gray-800 dark:ring-gray-800/5">
                                    <Menu.Item>
                                        <Link
                                            to={myAccountLink}
                                            className={classNames(
                                                "block px-3 py-1 text-sm leading-6 dark:text-white",
                                            )}
                                        >
                                            Account
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link
                                            to="#"
                                            onClick={logoutUser}
                                            className={classNames(
                                                "block px-3 py-1 text-sm leading-6 dark:text-white",
                                            )}
                                        >
                                            Sign Out
                                        </Link>
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopNav;
