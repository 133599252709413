import { ApolloProvider as BaseApolloProvider } from "@apollo/client";
import apolloClient from "@/apolloClient";

function ApolloProvider({ children }: { children: React.ReactNode }) {
    return (
        <BaseApolloProvider client={apolloClient}>
            {children}
        </BaseApolloProvider>
    );
}

export default ApolloProvider;
