import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import {
    GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY,
    GET_EBAY_ITEM_BRANDS_QUERY,
    GET_EBAY_ITEM_TAGS_QUERY,
} from "@/graphql/queries/ebay/general/ebayGeneralQueries";
import { BULK_UPDATE_EBAY_ITEMS_MUTATION } from "@/graphql/mutations/ebayMutations";
import { useQuery, useMutation } from "@apollo/client";
import Tooltip from "../../general/TooltipComponent";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    selectedProducts: any[];
}

const BulkEditEbayItemsModal: React.FC<ModalProps> = ({
    isOpen,
    closeModal,
    selectedProducts,
}) => {
    const navigate = useNavigate();

    // Remove duplicates from selected products which is just a list of strings
    const uniqueSelectedProducts = Array.from(new Set(selectedProducts));

    const { data: sourceLocationsData } = useQuery(
        GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY,
    );
    const { data: brandsData } = useQuery(GET_EBAY_ITEM_BRANDS_QUERY);
    const { data: tagsData } = useQuery(GET_EBAY_ITEM_TAGS_QUERY);

    const [updateBulkEbayItems] = useMutation(BULK_UPDATE_EBAY_ITEMS_MUTATION);

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);

        const itemIds = uniqueSelectedProducts;

        const variables: any = { itemIds: itemIds };

        const costOfGoodsPerItem = formData.get("costOfGoodsPerItem");
        if (costOfGoodsPerItem) {
            variables.costOfGoodsPerItem = parseFloat(
                costOfGoodsPerItem as string,
            ).toFixed(2);
        }

        const packingCostsPerItem = formData.get("packingCostsPerItem");
        if (packingCostsPerItem) {
            variables.packingCostsPerItem = parseFloat(
                packingCostsPerItem as string,
            ).toFixed(2);
        }

        const sourcedAt = formData.get("sourcedAt");
        if (sourcedAt) {
            variables.sourcedAt = new Date(sourcedAt as string).toISOString();
        }

        const sourceLocationId = formData.get("sourceLocation");
        if (sourceLocationId) {
            variables.sourceLocationId = sourceLocationId as string;
        }

        const storageLocation = formData.get("storageLocation");
        if (storageLocation) {
            variables.storageLocation = storageLocation as string;
        }

        const itemBrandId = formData.get("brand");
        if (itemBrandId) {
            variables.itemBrandId = itemBrandId as string;
        }

        const tagIds = formData.getAll("tags");
        if (tagIds.length > 0) {
            variables.tagIds = tagIds as string[];
        }

        try {
            await updateBulkEbayItems({ variables });
            closeModal();
            navigate(0);
        } catch (error) {
            console.error("Error updating eBay items:", error);
        }
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 border-2 bg-white bg-opacity-75 transition-opacity dark:bg-gray-500 dark:opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg border-2 border-gray-900 bg-white px-4 pb-4 text-left shadow-xl transition-all dark:border-0 dark:bg-gray-900 sm:my-8 sm:w-full sm:max-w-sm sm:p-6 lg:pt-4">
                                <div className="mb-3">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 dark:text-white"
                                    >
                                        Edit Product Details
                                    </Dialog.Title>
                                </div>
                                <div className="mb-3">
                                    <Dialog.Description className="text-sm dark:text-white">
                                        You are editing the details for{" "}
                                        {uniqueSelectedProducts.length} eBay
                                        items. Please fill in the details you
                                        would like to update. This will
                                        overwrite the current values for the
                                        selected items.
                                    </Dialog.Description>
                                </div>
                                <div className="mb-3">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="mt-1">
                                            <div className="flex items-center">
                                                <label
                                                    htmlFor="costOfGoodsPerItem"
                                                    className="block text-sm font-medium dark:text-white"
                                                >
                                                    Cost of Goods Per Item
                                                </label>
                                                <Tooltip text="You should enter the cost of each item per unit when you purchased it." />
                                            </div>
                                            <input
                                                type="number"
                                                step="0.01"
                                                name="costOfGoodsPerItem"
                                                id="costOfGoodsPerItem"
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <div className="flex items-center">
                                                <label
                                                    htmlFor="packingCostsPerItem"
                                                    className="block text-sm font-medium dark:text-white"
                                                    title="Packing Costs Per Item"
                                                >
                                                    Packing Costs Per Item
                                                </label>
                                                <Tooltip text="Enter the cost of how much it costs to pack each item. This could include the cost of the box, tape, bubble wrap, etc." />
                                            </div>
                                            <input
                                                type="number"
                                                step="0.01"
                                                name="packingCostsPerItem"
                                                id="packingCostsPerItem"
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <div className="flex items-center">
                                                <label
                                                    htmlFor="sourcedAt"
                                                    className="block text-sm font-medium dark:text-white"
                                                >
                                                    Purchased Date
                                                </label>
                                                <Tooltip text="Enter the date in which you purchased the item." />
                                            </div>
                                            <input
                                                type="date"
                                                name="sourcedAt"
                                                id="sourcedAt"
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <div className="flex items-center">
                                                <label
                                                    htmlFor="sourceLocation"
                                                    className="block text-sm font-medium dark:text-white"
                                                >
                                                    Source Location
                                                </label>
                                                <Tooltip text="Select the location where you purchased the item." />
                                            </div>
                                            <select
                                                name="sourceLocation"
                                                id="sourceLocation"
                                                defaultValue=""
                                                className="mt-1 block w-full rounded-md border border-gray-900 py-2 pl-3 pr-10 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                            >
                                                <option value="" disabled>
                                                    Select a source location
                                                </option>
                                                {sourceLocationsData?.sourceLocations.map(
                                                    (sourceLocation: any) => (
                                                        <option
                                                            key={
                                                                sourceLocation.id
                                                            }
                                                            value={
                                                                sourceLocation.id
                                                            }
                                                        >
                                                            {
                                                                sourceLocation.name
                                                            }
                                                        </option>
                                                    ),
                                                )}
                                            </select>
                                        </div>
                                        <div className="mt-1">
                                            <div className="flex items-center">
                                                <label
                                                    htmlFor="storageLocation"
                                                    className="block text-sm font-medium dark:text-white"
                                                >
                                                    Storage Location
                                                </label>
                                                <Tooltip text="Enter an identifier for the location where you store the item. e.g. Shelf 1, Box 2, etc." />
                                            </div>
                                            <input
                                                type="text"
                                                name="storageLocation"
                                                id="storageLocation"
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <div className="flex items-center">
                                                <label
                                                    htmlFor="brand"
                                                    className="block text-sm font-medium dark:text-white"
                                                >
                                                    Brand
                                                </label>
                                                <Tooltip text="Select the brand of the item." />
                                            </div>
                                            <select
                                                name="brand"
                                                id="brand"
                                                defaultValue=""
                                                className="mt-1 block w-full rounded-md border border-gray-900 py-2 pl-3 pr-10 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                            >
                                                <option value="" disabled>
                                                    Select a brand
                                                </option>
                                                {brandsData?.brands.map(
                                                    (brand: any) => (
                                                        <option
                                                            key={brand.id}
                                                            value={brand.id}
                                                        >
                                                            {brand.name}
                                                        </option>
                                                    ),
                                                )}
                                            </select>
                                        </div>
                                        <div className="mt-1">
                                            <div className="flex items-center">
                                                <label
                                                    htmlFor="tags"
                                                    className="block text-sm font-medium dark:text-white"
                                                >
                                                    Tags
                                                </label>
                                                <Tooltip text="Select one or multiple tags for the item. To select multiple tags, hold down the Ctrl key (Cmd on Mac) and click on the tags you want to select. To deselect, hold down the Ctrl key (Cmd on Mac) and click on the selected tag again." />
                                            </div>
                                            <select
                                                name="tags"
                                                id="tags"
                                                className="mt-1 block w-full rounded-md border border-gray-900 py-2 pl-3 pr-10 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                multiple
                                            >
                                                {tagsData?.ebayItemTags.map(
                                                    (tag: any) => (
                                                        <option
                                                            key={tag.id}
                                                            value={tag.id}
                                                        >
                                                            {tag.name}
                                                        </option>
                                                    ),
                                                )}
                                            </select>
                                        </div>
                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand dark:text-white"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="flex w-full justify-center rounded-md border-2 border-gray-900 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 dark:border-0 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default BulkEditEbayItemsModal;
