import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@/providers/AuthProvider";
import CircularLoader from "@/components/loading/LoadingComponent";

interface GuardProps {
    children: React.ReactNode;
}

const AuthGuard = ({ children }: GuardProps) => {
    console.log("Executing AuthGuard");

    const { isAuthenticated, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Executing AuthGuard useEffect");

        if (!loading && !isAuthenticated) {
            navigate("/login");
        }
    }, [isAuthenticated, loading, navigate]);

    if (loading) {
        return <CircularLoader />;
    }

    return isAuthenticated ? <>{children}</> : null;
};

export default AuthGuard;
