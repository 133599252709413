import { useQuery } from "@apollo/client";
import { MISSING_DATA_COUNT_QUERY } from "@/graphql/queries/ebay/general/ebayGeneralQueries";

const useMissingDataCount = () => {
    const { data, loading, error } = useQuery(MISSING_DATA_COUNT_QUERY);

    return {
        data,
        loading,
        error,
    };
};

export default useMissingDataCount;
