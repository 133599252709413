import React from "react";
import { useNavigate } from "react-router-dom";
import LogoComponent from "@/components/logo/LogoComponent";

const CancelPage: React.FC = () => {
    const navigate = useNavigate();

    const handleRetry = () => {
        navigate("/checkout");
    };

    const handleLearnMore = () => {
        window.location.href = "https://fliplytics.co.uk";
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Checkout Canceled
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="w-full rounded-lg bg-white p-8 shadow-md dark:bg-gray-800">
                    <p className="mb-6 text-center text-lg dark:text-white">
                        It seems like you didn&apost complete your purchase. But
                        don&apost worry, you can retry the checkout or learn
                        more about our product.
                    </p>

                    <div className="mb-6 flex justify-between">
                        <button
                            onClick={handleRetry}
                            className="hover:bg-brand-light dark:hover:bg-brand-light mr-2 w-full rounded-md bg-brand px-4 py-2 text-white focus:outline-none dark:bg-gray-700"
                        >
                            Retry Checkout
                        </button>
                        <button
                            onClick={handleLearnMore}
                            className="ml-2 w-full rounded-md bg-gray-200 px-4 py-2 text-gray-900 hover:bg-gray-300 focus:outline-none dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
                        >
                            Learn More
                        </button>
                    </div>

                    <p className="text-center text-sm dark:text-gray-400">
                        Need help?{" "}
                        <a
                            href="https://fliplytics.co.uk/contact"
                            className="hover:text-brand-light font-semibold text-brand"
                        >
                            Contact our support team
                        </a>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CancelPage;
