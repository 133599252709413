import React from "react";

interface ProductTotalsComponentProps {
    product: any;
}

const ProductTotalsComponent: React.FC<ProductTotalsComponentProps> = ({
    product,
}) => {
    const totalSales = product?.profitAndRoi?.totalSales || 0;
    const totalRevenue = product?.profitAndRoi?.totalRevenue || 0;
    const totalProfit = product?.profitAndRoi?.totalProfitLoss || 0;
    const totalCostOfGoods = product?.profitAndRoi?.totalCosts || 0;
    const totalROI = product?.profitAndRoi?.totalReturnOnInvestment || 0;

    const getStatClass = (name: string, value: number) => {
        if (isNaN(value)) return "text-gray-900 dark:text-white";
        if (name === "Total Costs") return "text-red-500";
        return value >= 0 ? "text-green-500" : "text-red-500";
    };

    // Ensure all totals are valid numbers and convert to fixed-point strings
    const stats = [
        {
            name: "Total Sales",
            stat: totalSales.toString(),
            prefix: "",
            suffix: "",
        },
        {
            name: "Total Revenue",
            stat: totalRevenue.toString(),
            prefix: "£",
            suffix: "",
        },
        {
            name: "Total Profit",
            stat: totalProfit.toString(),
            prefix: "£",
            suffix: "",
        },
        {
            name: "Total Costs",
            stat: totalCostOfGoods.toString(),
            prefix: "£",
        },
        {
            name: "Total ROI",
            stat: totalROI.toString(),
            prefix: "",
            suffix: "%",
        },
    ];

    return (
        <dl className="grid grid-cols-2 gap-5 sm:grid-cols-5">
            {stats.map((item) => (
                <div
                    key={item.name}
                    className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:p-6"
                >
                    <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                        {item.name}
                    </dt>
                    <dd
                        className={`mt-1 text-3xl font-semibold tracking-tight ${getStatClass(item.name, parseFloat(item.stat))}`}
                    >
                        {item.prefix}
                        {item.stat}
                        {item.suffix}
                    </dd>
                </div>
            ))}
        </dl>
    );
};

export default ProductTotalsComponent;
