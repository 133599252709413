import { useState } from "react";
import * as htmlToImage from "html-to-image";

interface ScreenshotOptions {
    type?: "jpeg" | "png" | "svg";
    quality?: number;
    backgroundColor?: string;
    width?: number;
    height?: number;
    style?: Record<string, string>;
    cacheBust?: boolean;
    filter?: (node: HTMLElement) => boolean;
    imagePlaceholder?: string;
}

interface UseScreenshotReturn {
    image: string | null;
    takeScreenshot: (
        node: HTMLElement,
        options?: ScreenshotOptions,
    ) => Promise<string>;
    downloadScreenshot: (
        node: HTMLElement,
        filename: string,
        options?: ScreenshotOptions,
    ) => Promise<void>;
    error: Error | null;
}

const useScreenshot = (): UseScreenshotReturn => {
    const [image, setImage] = useState<string | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const takeScreenshot = async (
        node: HTMLElement,
        options: ScreenshotOptions = {},
    ): Promise<string> => {
        try {
            let dataUrl: string;
            switch (options.type) {
                case "jpeg":
                    dataUrl = await htmlToImage.toJpeg(node, options);
                    break;
                case "svg":
                    dataUrl = await htmlToImage.toSvg(node, options);
                    break;
                case "png":
                default:
                    dataUrl = await htmlToImage.toPng(node, options);
                    break;
            }
            setImage(dataUrl);
            return dataUrl;
        } catch (err) {
            setError(err as Error);
            throw err;
        }
    };

    const downloadScreenshot = async (
        node: HTMLElement,
        filename: string,
        options: ScreenshotOptions = {},
    ): Promise<void> => {
        try {
            const dataUrl = await takeScreenshot(node, options);
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            setError(err as Error);
            throw err;
        }
    };

    return {
        image,
        takeScreenshot,
        downloadScreenshot,
        error,
    };
};

export { useScreenshot };
