import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PAGINATED_EBAY_ITEM_TAGS_QUERY } from "@/graphql/queries/ebay/tags/ebayTagsQueries";

const useTags = () => {
    const [pagination, setPagination] = useState({
        after: null,
        before: null,
    });

    const { data, loading, error } = useQuery(
        GET_PAGINATED_EBAY_ITEM_TAGS_QUERY,
        {
            variables: { ...pagination },
        },
    );

    const handleNextPage = () => {
        if (data?.paginatedEbayItemTags?.pageInfo?.hasNextPage) {
            setPagination((prev) => ({
                ...prev,
                after: data.paginatedEbayItemTags.pageInfo.endCursor,
            }));
        }
    };

    const handlePreviousPage = () => {
        if (data?.paginatedEbayItemTags?.pageInfo?.hasPreviousPage) {
            setPagination((prev) => ({
                ...prev,
                before: data.paginatedEbayItemTags.pageInfo.startCursor,
            }));
        }
    };

    return {
        data,
        loading,
        error,
        handleNextPage,
        handlePreviousPage,
        pageInfo: data?.paginatedEbayItemTags?.pageInfo || {},
    };
};

export default useTags;
