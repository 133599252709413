import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_MISSING_DATA_EBAY_SALES_QUERY } from "@/graphql/queries/ebay/sold/ebaySoldQueries";
import { subDays, startOfToday, endOfToday } from "date-fns";

const useMissingDataEbaySales = () => {
    const initialFilters = {
        ebayItem_Tags_Id: null,
        ebayItem_SourceLocation_Id: null,
        ebayItem_ListedPrice_Lte: null,
        ebayItem_ListedPrice_Gte: null,
        ebayItem_ItemCategory_Id: null,
        ebayItem_ItemBrand_Id: null,
        soldPrice_Lte: null,
        soldPrice_Gte: null,
        totalProfitLoss_Lte: null,
        totalProfitLoss_Gte: null,
        totalReturnOnInvestment_Lte: null,
        totalReturnOnInvestment_Gte: null,
        soldAt_Gte: subDays(startOfToday(), 30).toISOString(),
        soldAt_Lte: endOfToday().toISOString(),
    };

    const [filters, setFilters] = useState(initialFilters);
    const [pagination, setPagination] = useState({
        after: null,
        before: null,
    });

    const { data, loading, error, refetch } = useQuery(
        GET_MISSING_DATA_EBAY_SALES_QUERY,
        {
            variables: { ...filters, ...pagination },
        },
    );

    const handleFilterChange = (name: string, value: any) => {
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const applyFilters = () => {
        refetch({ ...filters, ...pagination });
    };

    const handleNextPage = () => {
        if (data?.missingDataEbaySales?.pageInfo?.hasNextPage) {
            setPagination((prev) => ({
                ...prev,
                after: data.missingDataEbaySales.pageInfo.endCursor,
            }));
            applyFilters();
        }
    };

    const handlePreviousPage = () => {
        if (data?.missingDataEbaySales?.pageInfo?.hasPreviousPage) {
            setPagination((prev) => ({
                ...prev,
                before: data.missingDataEbaySales.pageInfo.startCursor,
            }));
            applyFilters();
        }
    };

    const resetFilters = () => {
        setFilters(initialFilters);
        refetch({ ...initialFilters, ...pagination });
    };

    return {
        data,
        loading,
        error,
        filters,
        setFilters: handleFilterChange,
        applyFilters,
        handleNextPage,
        handlePreviousPage,
        pageInfo: data?.missingDataEbaySales?.pageInfo || {},
        resetFilters,
    };
};

export default useMissingDataEbaySales;
