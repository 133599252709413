import UnsoldEbayItemsTableComponent from "@/components/dashboard/inventory/UnsoldEbayItemsTableComponent";
import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";
import { Link } from "react-router-dom";

const unlistedProductsColumns = [
    {
        key: "itemName",
        label: "Product",
        render: (value: string, item?: any) => (
            <Link
                to={`/dashboard/products/${decodeBase64Id(item.id)}`}
                className="text-blue-500 hover:underline"
            >
                {value}
            </Link>
        ),
    },
    {
        key: "listedAt",
        label: "Previously Listed",
        render: (value: string) =>
            value ? new Date(value).toLocaleDateString() : "",
    },
    {
        key: "costOfGoodsPerItem",
        label: "Cost",
        render: (value: number) => (value != null ? `£${value}` : ""),
    },
    {
        key: "listedPrice",
        label: "Previously Listed Price",
        render: (value: number) => (value != null ? `£${value}` : ""),
    },
];

export default function UnlistedProductsPage() {
    return (
        <>
            <UnsoldEbayItemsTableComponent columns={unlistedProductsColumns} />
        </>
    );
}
