import MissingDataEbaySalesTableComponent from "@/components/dashboard/sold/MissingDataEbaySalesTableComponent";
import { Link } from "react-router-dom";
import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";

// status should be a boolean based off of if shippedAt is null or not
// totalFees should be the sum of ebayFees, ebayPromotionFees, and shippingCost, if they are not null, otherwise 0

const missingDataSoldProductsColumns = [
    {
        key: "itemName",
        label: "Product",
        render: (_value?: any, record?: any) => (
            <Link
                to={`/dashboard/products/${decodeBase64Id(record.ebayItem.id)}`}
                className="text-blue-500 hover:underline"
            >
                {record.ebayItem.itemName}
            </Link>
        ),
    },
    // {
    //     key: "status",
    //     label: "Status",
    //     render: (_value: any, record: any) =>
    //         record.shippedAt ? "Shipped" : "Ready To Ship",
    // },
    {
        key: "soldAt",
        label: "Sold At",
        render: (value: any) =>
            value ? new Date(value).toLocaleDateString() : "",
    },
    {
        key: "totalProfitLoss",
        label: "Profit/Loss",
        render: (_value: any, record: any) =>
            record.profitAndRoi?.totalProfitLoss != null
                ? `£${record.profitAndRoi.totalProfitLoss}`
                : "",
    },
    {
        key: "costOfGoodsPerItem",
        label: "Cost",
        render: (_value: any, record: any) =>
            record.ebayItem.costOfGoodsPerItem != null
                ? `£${record.ebayItem.costOfGoodsPerItem}`
                : "",
    },
    {
        key: "totalFees",
        label: "Total Fees",
        render: (_value: any, record: any) => {
            const ebayFees = Number(record.ebayFees) || 0;
            const ebayPromotionFees = Number(record.ebayPromotionFees) || 0;
            const shippingCost = Number(record.shippingCost) || 0;
            const totalFees = ebayFees + ebayPromotionFees + shippingCost;

            // Debug logging

            return `£${totalFees.toFixed(2)}`;
        },
    },
    {
        key: "soldPrice",
        label: "Sold Price",
        render: (value: any) => (value != null ? `£${value}` : ""),
    },
    {
        key: "totalReturnOnInvestment",
        label: "ROI",
        render: (_value: any, record: any) =>
            record.profitAndRoi?.totalReturnOnInvestment != null
                ? `${record.profitAndRoi.totalReturnOnInvestment}%`
                : "",
    },
];

export default function MissingDataEbaySalesPage() {
    return (
        <>
            <MissingDataEbaySalesTableComponent
                columns={missingDataSoldProductsColumns}
            />
        </>
    );
}
