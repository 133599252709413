import React, { useState } from "react";
import {
    DocumentTextIcon,
    UserCircleIcon,
    ChatBubbleLeftEllipsisIcon,
    TruckIcon,
    TagIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { formatDate } from "@/helpers/common/formatDate";
import EditSaleModal from "./modals/EditSaleModal";
import BulkEditSaleModal from "./modals/BulkEditSalesModal";
interface Event {
    id: number;
    eventType: string;
    eventTime: string;
    sale: any;
}

interface Product {
    ebayItemEvents: Event[];
    ebayItemId?: string;
    listedPrice?: number;
}

interface ProductTimelineComponentProps {
    product: Product;
}

const renderEventContent = (
    event: Event,
    product: Product,
    openModal: (sale: any) => void,
) => {
    const formattedDate = formatDate(event.eventTime);
    switch (event.eventType) {
        case "ITEM_LISTED":
            return (
                <>
                    <div className="h-9 rounded-full bg-gray-700 p-2 dark:text-white">
                        <DocumentTextIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="min-w-0 flex-1 py-1.5 pl-2">
                        <div className="text-sm text-gray-500">
                            <p className="font-medium text-gray-900 dark:text-white">
                                Listed
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                {formattedDate}
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                <Link
                                    to={`https://ebay.co.uk/itm/${product.ebayItemId}`}
                                    className="text-brand hover:underline"
                                >
                                    Listed
                                </Link>{" "}
                                on eBay for £{product.listedPrice}
                            </p>
                        </div>
                    </div>
                </>
            );
        case "ITEM_SOLD":
            return (
                <>
                    <div className="h-9 rounded-full p-2 dark:bg-gray-700 dark:text-white">
                        <UserCircleIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="min-w-0 flex-1 py-1.5 pl-2">
                        <div className="text-sm text-gray-500">
                            <p className="font-medium text-gray-900 dark:text-white">
                                Sold
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                {formattedDate}
                            </p>
                            <div className="text-gray-500 dark:text-gray-400">
                                <table className="min-w-full divide-y divide-gray-700">
                                    <tbody>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                            >
                                                eBay Order ID
                                            </th>
                                            <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-2">
                                                {event.sale.ebayOrderId}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                            >
                                                Quantity Sold
                                            </th>
                                            <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-2">
                                                {event.sale.quantitySold}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                            >
                                                Sold Price
                                            </th>
                                            <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-2">
                                                £{event.sale.soldPrice}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                            >
                                                Shipping Price
                                            </th>
                                            <td className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-2">
                                                £{event.sale.shippingPrice || 0}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                            >
                                                eBay Fees
                                            </th>
                                            <td className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-2">
                                                £{event.sale.ebayFees}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                            >
                                                eBay Promotion Fees
                                            </th>
                                            <td className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-2">
                                                £{event.sale.ebayPromotionFees}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                            >
                                                Shipping Cost
                                            </th>
                                            <td className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-2">
                                                £{event.sale.shippingCost || 0}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                            >
                                                Profit/Loss
                                            </th>
                                            <td className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-2">
                                                £
                                                {event.sale.profitAndRoi
                                                    ?.totalProfitLoss || 0}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                            >
                                                Return on Investment
                                            </th>
                                            <td className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white sm:pl-2">
                                                {event.sale.profitAndRoi
                                                    ?.totalReturnOnInvestment ||
                                                    0}
                                                %
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <button
                                onClick={() => openModal(event.sale)}
                                className="mt-2 inline-flex justify-center rounded-lg border border-black bg-white px-4 py-2 text-sm text-black dark:border-white dark:bg-gray-700 dark:text-white"
                            >
                                Edit Sale
                            </button>
                        </div>
                    </div>
                </>
            );
        case "ITEM_UNSOLD":
            return (
                <>
                    <div className="h-9 rounded-full p-2 dark:bg-gray-700 dark:text-white">
                        <ChatBubbleLeftEllipsisIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="min-w-0 flex-1 py-1.5 pl-2">
                        <div className="text-sm text-gray-500">
                            <p className="font-medium text-gray-900 dark:text-white">
                                Unsold
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                {formattedDate}
                            </p>
                        </div>
                    </div>
                </>
            );
        case "ITEM_RETURNED":
            return (
                <>
                    <div className="h-9 rounded-full p-2 dark:bg-gray-700 dark:text-white">
                        <TruckIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="min-w-0 flex-1 py-1.5 pl-2">
                        <div className="text-sm text-gray-500">
                            <p className="font-medium text-gray-900 dark:text-white">
                                Returned
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                {formattedDate}
                            </p>
                        </div>
                    </div>
                </>
            );
        case "ITEM_SHIPPED":
            return (
                <>
                    <div className="h-9 rounded-full p-2 dark:bg-gray-700 dark:text-white">
                        <TruckIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="min-w-0 flex-1 py-1.5 pl-2">
                        <div className="text-sm text-gray-500">
                            <p className="font-medium text-gray-900 dark:text-white">
                                Shipped
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                {formattedDate}
                            </p>
                        </div>
                    </div>
                </>
            );
        case "ITEM_REVISED":
            return (
                <>
                    <div className="h-9 rounded-full p-2 dark:bg-gray-700 dark:text-white">
                        <DocumentTextIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="min-w-0 flex-1 py-1.5 pl-2">
                        <div className="text-sm text-gray-500">
                            <p className="font-medium text-gray-900 dark:text-white">
                                Revised
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                {formattedDate}
                            </p>
                        </div>
                    </div>
                </>
            );
        case "ITEM_SOURCED":
            return (
                <>
                    <div className="h-9 rounded-full p-2 dark:bg-gray-700 dark:text-white">
                        <TagIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="min-w-0 flex-1 py-1.5 pl-2">
                        <div className="text-sm text-gray-500">
                            <p className="font-medium text-gray-900 dark:text-white">
                                Sourced
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                {formattedDate}
                            </p>
                        </div>
                    </div>
                </>
            );
        case "ITEM_RELISTED":
            return (
                <>
                    <div className="h-9 rounded-full p-2 dark:bg-gray-700 dark:text-white">
                        <DocumentTextIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="min-w-0 flex-1 py-1.5 pl-2">
                        <div className="text-sm text-gray-500">
                            <p className="font-medium text-gray-900 dark:text-white">
                                Relisted
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                {formattedDate}
                            </p>
                        </div>
                    </div>
                </>
            );
        default:
            return null;
    }
};

const ProductTimelineComponent: React.FC<ProductTimelineComponentProps> = ({
    product,
}) => {
    const [selectedSale, setSelectedSale] = useState<any | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isBulkEditModalOpen, setIsBulkEditModalOpen] =
        useState<boolean>(false);

    const openModal = (sale: any) => {
        setSelectedSale(sale);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedSale(null);
    };

    const openEditAllSalesModal = () => {
        setIsBulkEditModalOpen(true);
    };

    const closeEditAllSalesModal = () => {
        setIsBulkEditModalOpen(false);
    };

    // Sort events by eventTime in descending order
    const sortedEvents = product.ebayItemEvents
        .slice()
        .sort(
            (a, b) =>
                new Date(b.eventTime).getTime() -
                new Date(a.eventTime).getTime(),
        );

    return (
        <div className="relative z-0 mt-5 overflow-hidden rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:p-6">
            <button
                onClick={openEditAllSalesModal}
                className="absolute right-4 top-4 z-10 inline-flex items-center justify-center rounded-lg border border-black bg-white px-4 py-2 text-sm text-black dark:border-white dark:bg-gray-700 dark:text-white"
                type="button"
            >
                Edit All Sales
            </button>

            <ul role="list" className="-mb-8">
                {sortedEvents.map((event, eventIdx) => (
                    <li key={event.id}>
                        <div className="relative pb-8">
                            {eventIdx !== sortedEvents.length - 1 ? (
                                <span
                                    className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-700"
                                    aria-hidden="true"
                                />
                            ) : null}
                            <div className="relative flex items-start space-x-3">
                                <div className="relative px-1">
                                    <div className="flex justify-center">
                                        {renderEventContent(
                                            event,
                                            product,
                                            openModal,
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            <EditSaleModal
                isOpen={isModalOpen}
                closeModal={closeModal}
                sale={selectedSale}
            />
            <BulkEditSaleModal
                isOpen={isBulkEditModalOpen}
                closeModal={closeEditAllSalesModal}
                saleEvents={product.ebayItemEvents.filter(
                    (event) => event.eventType === "ITEM_SOLD",
                )}
            />
        </div>
    );
};

export default ProductTimelineComponent;
