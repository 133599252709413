import { gql } from "@apollo/client";

export const SIGNUP_USER_MUTATION = gql`
    mutation SignupUser(
        $email: String!
        $password: String!
        $firstName: String!
        $lastName: String!
    ) {
        signupUser(
            email: $email
            password: $password
            firstName: $firstName
            lastName: $lastName
        ) {
            status
            message
            success
            user {
                firstName
                lastName
                ebayAccount {
                    id
                    initialised
                }
                emailVerified
            }
        }
    }
`;

export const LOGIN_USER_MUTATION = gql`
    mutation LoginUser($email: String!, $password: String!) {
        loginUser(email: $email, password: $password) {
            status
            message
            success
            user {
                firstName
                lastName
                ebayAccount {
                    id
                    initialised
                }
                emailVerified
            }
        }
    }
`;

export const LOGOUT_USER_MUTATION = gql`
    mutation LogoutUser {
        logoutUser {
            success
        }
    }
`;

export const RESEND_VERIFICATION_EMAIL_MUTATION = gql`
    mutation ResendVerificationEmailMutation {
        resendVerificationEmail {
            status
            message
            success
        }
    }
`;

export const VERIFY_EMAIL_MUTATION = gql`
    mutation VerifyEmail($token: String!) {
        verifyEmail(token: $token) {
            status
            message
            success
        }
    }
`;

export const REQUEST_PASSWORD_RESET_MUTATION = gql`
    mutation RequestPasswordReset($email: String!) {
        requestPasswordReset(email: $email) {
            success
        }
    }
`;

export const RESET_PASSWORD_MUTATION = gql`
    mutation ResetPassword(
        $uid: String!
        $token: String!
        $newPassword: String!
    ) {
        resetPassword(uid: $uid, token: $token, newPassword: $newPassword) {
            success
        }
    }
`;
