import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import LogoComponent from "@/components/logo/LogoComponent";
import CircularLoader from "@/components/loading/LoadingComponent";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD_MUTATION } from "@/graphql/mutations/authMutations";

const validatePassword = (password: string): boolean => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/;
    return passwordRegex.test(password);
};

export default function ResetPasswordPage() {
    console.log("ResetPasswordPage");

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const { uid, token } = useParams<{ uid: string; token: string }>();

    console.log("uid", uid);
    console.log("token", token);

    const navigate = useNavigate();
    const [resetPassword, { loading, error }] = useMutation(
        RESET_PASSWORD_MUTATION,
    );

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
        setPasswordError(
            validatePassword(e.target.value)
                ? ""
                : "Password must be 12+ characters, contain a capital letter, lowercase letter, and a number.",
        );
    };

    const handleConfirmPasswordChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError(
            e.target.value === newPassword ? "" : "Passwords do not match.",
        );
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        console.log("handleSubmit");

        e.preventDefault();
        if (!passwordError && !confirmPasswordError) {
            try {
                const { data } = await resetPassword({
                    variables: { uid, token, newPassword },
                });
                if (data?.resetPassword?.success) {
                    navigate("/login");
                } else {
                    setPasswordError(
                        "Failed to reset password. Please try again.",
                    );
                }
            } catch (err) {
                console.error("Failed to reset password", err);
            }
        }
    };

    if (loading) {
        return <CircularLoader />;
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Reset Your Password
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium leading-6 dark:text-white"
                        >
                            New Password
                        </label>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="new-password"
                                required
                                value={newPassword}
                                onChange={handlePasswordChange}
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-brand focus:ring-2 focus:ring-inset focus:ring-brand dark:text-white dark:ring-white/10 dark:focus:ring-brand sm:text-sm sm:leading-6"
                            />
                        </div>
                        {passwordError && (
                            <p className="mt-2 text-sm text-red-500">
                                {passwordError}
                            </p>
                        )}
                    </div>

                    <div>
                        <label
                            htmlFor="confirm-password"
                            className="block text-sm font-medium leading-6 dark:text-white"
                        >
                            Confirm New Password
                        </label>
                        <div className="mt-2">
                            <input
                                id="confirm-password"
                                name="confirm-password"
                                type="password"
                                autoComplete="new-password"
                                required
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-brand focus:ring-2 focus:ring-inset focus:ring-brand dark:text-white dark:ring-white/10 dark:focus:ring-brand sm:text-sm sm:leading-6"
                            />
                        </div>
                        {confirmPasswordError && (
                            <p className="mt-2 text-sm text-red-500">
                                {confirmPasswordError}
                            </p>
                        )}
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
                        >
                            Reset Password
                        </button>
                    </div>
                </form>

                {error && (
                    <p className="mt-4 text-center text-sm text-red-500">
                        Error occurred. Please try again later.
                    </p>
                )}

                <p className="mt-10 text-center text-sm dark:text-gray-400">
                    Remember your password?{" "}
                    <Link
                        to="/login"
                        className="font-semibold leading-6 text-brand hover:text-brand"
                    >
                        Sign in here
                    </Link>
                </p>
            </div>
        </div>
    );
}
