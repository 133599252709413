import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { SEARCH_QUERY } from "@/graphql/queries/ebay/search/searchQueries";
import { Link, useLocation } from "react-router-dom";
import CircularLoader from "@/components/loading/LoadingComponent";
import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";

interface EbayItemType {
    id: string;
    itemName: string;
    listedAt: string;
    costOfGoodsPerItem: number;
    listedPrice: number;
}

interface EbaySaleType {
    id: string;
    ebay_item: EbayItemType;
    soldAt: string;
}

interface Column {
    key: keyof EbayItemType;
    label: string;
    render?: (value: any, item?: any) => React.ReactNode;
}

const columns: Column[] = [
    {
        key: "itemName",
        label: "Product",
        render: (value: string, item?: EbayItemType) => (
            <Link
                to={`/dashboard/products/${decodeBase64Id(item!.id)}`}
                className="text-blue-500 hover:underline"
            >
                {value}
            </Link>
        ),
    },
    {
        key: "listedAt",
        label: "Listed",
        render: (value: string) =>
            value ? new Date(value).toLocaleDateString() : "",
    },
    {
        key: "costOfGoodsPerItem",
        label: "Cost",
        render: (value: number) => (value != null ? `£${value}` : ""),
    },
    {
        key: "listedPrice",
        label: "Listed Price",
        render: (value: number) => (value != null ? `£${value}` : ""),
    },
];

const SearchResultsPage: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get("query");

    const { data, loading, error } = useQuery(SEARCH_QUERY, {
        variables: { searchTerm },
        skip: !searchTerm,
    });

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const handleCheckboxChange = (id: string) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(id)
                ? prevSelectedItems.filter((itemId) => itemId !== id)
                : [...prevSelectedItems, id],
        );
    };

    if (loading) return <CircularLoader />;
    if (error) return <p>Error: {error.message}</p>;

    const searchResults: (EbayItemType | EbaySaleType)[] = data?.search || [];

    return (
        <div className="h-full dark:bg-gray-900">
            <div className="py-2 dark:bg-gray-900">
                <div className="px-2 sm:px-2 lg:px-1">
                    <div className="flow-root">
                        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                {/* Add overflow-x-auto for horizontal scroll */}
                                <div className="max-h-[calc(100vh-10rem)] overflow-x-auto overflow-y-hidden">
                                    <div className="max-h-[calc(100vh-10rem)] overflow-y-auto">
                                        <table className="min-w-full table-fixed divide-y dark:divide-gray-700">
                                            <thead className="sticky top-0 z-30 dark:bg-gray-900">
                                                <tr>
                                                    <th
                                                        colSpan={
                                                            columns.length + 1
                                                        }
                                                        className="text-left"
                                                    >
                                                        <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                            Search Results
                                                        </h3>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0">
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                const isChecked =
                                                                    e.target
                                                                        .checked;
                                                                if (isChecked) {
                                                                    const allItemIds =
                                                                        searchResults.map(
                                                                            (
                                                                                item:
                                                                                    | EbayItemType
                                                                                    | EbaySaleType,
                                                                            ) =>
                                                                                decodeBase64Id(
                                                                                    item.id,
                                                                                ),
                                                                        );
                                                                    setSelectedItems(
                                                                        allItemIds,
                                                                    );
                                                                } else {
                                                                    setSelectedItems(
                                                                        [],
                                                                    );
                                                                }
                                                            }}
                                                            checked={
                                                                searchResults.length >
                                                                    0 &&
                                                                selectedItems.length ===
                                                                    searchResults.length
                                                            }
                                                        />
                                                    </th>
                                                    {columns.map((col) => (
                                                        <th
                                                            key={col.key}
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                                        >
                                                            {col.label}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y dark:divide-gray-800">
                                                {searchResults.map(
                                                    (item: any) => {
                                                        const itemId =
                                                            decodeBase64Id(
                                                                item.id,
                                                            );
                                                        const isSale =
                                                            item.__typename ===
                                                            "EbaySaleType";
                                                        const productDetails =
                                                            isSale
                                                                ? item.ebay_item
                                                                : item;

                                                        return (
                                                            <tr key={item.id}>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedItems.includes(
                                                                            itemId,
                                                                        )}
                                                                        onChange={() =>
                                                                            handleCheckboxChange(
                                                                                itemId,
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                                {columns.map(
                                                                    (col) => (
                                                                        <td
                                                                            key={
                                                                                col.key
                                                                            }
                                                                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0"
                                                                        >
                                                                            {col.render
                                                                                ? col.render(
                                                                                      productDetails[
                                                                                          col
                                                                                              .key
                                                                                      ],
                                                                                      productDetails,
                                                                                  )
                                                                                : productDetails[
                                                                                      col
                                                                                          .key
                                                                                  ] ||
                                                                                  ""}
                                                                        </td>
                                                                    ),
                                                                )}
                                                            </tr>
                                                        );
                                                    },
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchResultsPage;
