import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { UPDATE_SOURCE_LOCATION_MUTATION } from "@/graphql/mutations/ebayMutations"; // Use mutation for updating
import { useMutation } from "@apollo/client";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    sourceLocation: any; // The source location to be edited
}

const EditSourceLocationModal: React.FC<ModalProps> = ({
    isOpen,
    closeModal,
    sourceLocation,
}) => {
    const navigate = useNavigate();

    const [updateSourceLocation] = useMutation(UPDATE_SOURCE_LOCATION_MUTATION);

    // Initialize state with the current source location data
    const [name, setName] = useState(sourceLocation?.name || "");
    const [address, setAddress] = useState(sourceLocation?.address || "");
    const [city, setCity] = useState(sourceLocation?.city || "");
    const [state, setState] = useState(sourceLocation?.state || "");
    const [zipCode, setZipCode] = useState(sourceLocation?.zipCode || "");
    const [country, setCountry] = useState(sourceLocation?.country || "");

    useEffect(() => {
        // Update form values when the source location changes
        if (sourceLocation) {
            setName(sourceLocation.name || "");
            setAddress(sourceLocation.address || "");
            setCity(sourceLocation.city || "");
            setState(sourceLocation.state || "");
            setZipCode(sourceLocation.zipCode || "");
            setCountry(sourceLocation.country || "");
        }
    }, [sourceLocation]);

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const variables: any = {
            id: sourceLocation.id,
            name,
            address,
            city,
            state,
            zipCode,
            country,
        };

        try {
            await updateSourceLocation({ variables });
            closeModal();
            navigate(0); // Refresh page
        } catch (error) {
            console.error("Error updating source location:", error);
        }
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 border-2 bg-white bg-opacity-75 transition-opacity dark:bg-gray-500" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg border-2 border-gray-900 bg-white px-4 pb-4 text-left shadow-xl transition-all dark:border-0 dark:bg-gray-900 sm:my-8 sm:w-full sm:max-w-sm sm:p-6 lg:pt-4">
                                <div className="mb-3">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 dark:text-white"
                                    >
                                        Edit Source Location
                                    </Dialog.Title>
                                </div>
                                <div className="mb-3">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="name"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={name}
                                                onChange={(e) =>
                                                    setName(e.target.value)
                                                }
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                maxLength={30}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="address"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Address
                                            </label>
                                            <input
                                                type="text"
                                                name="address"
                                                id="address"
                                                value={address}
                                                onChange={(e) =>
                                                    setAddress(e.target.value)
                                                }
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                maxLength={100}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="city"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                City
                                            </label>
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                value={city}
                                                onChange={(e) =>
                                                    setCity(e.target.value)
                                                }
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                maxLength={50}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="state"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                State
                                            </label>
                                            <input
                                                type="text"
                                                name="state"
                                                id="state"
                                                value={state}
                                                onChange={(e) =>
                                                    setState(e.target.value)
                                                }
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                maxLength={50}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="zipCode"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Zip Code
                                            </label>
                                            <input
                                                type="text"
                                                name="zipCode"
                                                id="zipCode"
                                                value={zipCode}
                                                onChange={(e) =>
                                                    setZipCode(e.target.value)
                                                }
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                maxLength={10}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="country"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Country
                                            </label>
                                            <input
                                                type="text"
                                                name="country"
                                                id="country"
                                                value={country}
                                                onChange={(e) =>
                                                    setCountry(e.target.value)
                                                }
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                maxLength={50}
                                            />
                                        </div>

                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand dark:text-white"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="flex w-full justify-center rounded-md border-2 border-gray-900 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 dark:border-0 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EditSourceLocationModal;
