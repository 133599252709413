import {
    FullScreenElement,
    FullScreenDocument,
} from "@/types/toggleFullScreenTypes";

export const toggleFullScreen = (): void => {
    const doc = document.documentElement as FullScreenElement;
    const fullScreenDoc = document as FullScreenDocument;

    if (!document.fullscreenElement) {
        if (doc.requestFullscreen) {
            doc.requestFullscreen();
        } else if (doc.mozRequestFullScreen) {
            doc.mozRequestFullScreen(); // Firefox
        } else if (doc.webkitRequestFullscreen) {
            doc.webkitRequestFullscreen(); // Chrome, Safari, and Opera
        } else if (doc.msRequestFullscreen) {
            doc.msRequestFullscreen(); // IE/Edge
        }
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (fullScreenDoc.mozCancelFullScreen) {
            fullScreenDoc.mozCancelFullScreen(); // Firefox
        } else if (fullScreenDoc.webkitExitFullscreen) {
            fullScreenDoc.webkitExitFullscreen(); // Chrome, Safari, and Opera
        } else if (fullScreenDoc.msExitFullscreen) {
            fullScreenDoc.msExitFullscreen(); // IE/Edge
        }
    }
};
