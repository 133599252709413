import { gql } from "@apollo/client";

export const GET_TIER_PRICING_QUERY = gql`
    query GetTierPricingQuery {
        tierPricing {
            monthlyPrice
            tier
            yearlyPrice
            currency
        }
    }
`;

export const GET_SUBSCRIPTION_QUERY = gql`
    query GetSubscriptionQuery {
        subscription {
            id
            stripeSubscriptionId
            status
            currentPeriodStart
            currentPeriodEnd
            trialEnd
            createdAt
            updatedAt
        }
    }
`;
