import { gql } from "@apollo/client";

export const GET_EBAY_ITEM_TAGS_QUERY = gql`
    query GetEbayItemTags {
        ebayItemTags {
            id
            name
        }
    }
`;
export const GET_EBAY_ITEM_CATEGORIES_QUERY = gql`
    query GetEbayItemCategories {
        itemCategories {
            id
            categoryId
            categoryName
        }
    }
`;
export const GET_EBAY_ITEM_BRANDS_QUERY = gql`
    query GetEbayItemBrands {
        brands {
            id
            name
        }
    }
`;
export const GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY = gql`
    query GetEbayItemSourceLocations {
        sourceLocations {
            id
            name
            address
            city
            state
            zipCode
            country
        }
    }
`;
export const GET_DASHBOARD_STATS_QUERY = gql`
    query GetDashboardStatsQuery(
        $ebayItemListedAtGte: DateTime
        $ebayItemListedAtLte: DateTime
        $ebaySaleSoldAtGte: DateTime
        $ebaySaleSoldAtLte: DateTime
        $itemBrandId: ID
        $itemCategoryId: ID
        $sourceLocationId: ID
        $tags: [ID]
    ) {
        dashboardStats(
            ebayItemListedAtGte: $ebayItemListedAtGte
            ebayItemListedAtLte: $ebayItemListedAtLte
            ebaySaleSoldAtGte: $ebaySaleSoldAtGte
            ebaySaleSoldAtLte: $ebaySaleSoldAtLte
            itemBrandId: $itemBrandId
            itemCategoryId: $itemCategoryId
            sourceLocationId: $sourceLocationId
            tags: $tags
        ) {
            avgTransactionValue
            newListings
            productsSold
            returnOnInvestment
            totalProfit
            totalSales
            avgTimeToSell
        }
    }
`;

export const MOST_PROFITABLE_PRODUCT_QUERY = gql`
    query MostprofitableProductQuery(
        $ebaySaleSoldAtGte: DateTime
        $ebaySaleSoldAtLte: DateTime
        $itemBrandId: ID
        $itemCategoryId: ID
        $sourceLocationId: ID
        $tags: [ID]
    ) {
        mostProfitableProduct(
            ebaySaleSoldAtGte: $ebaySaleSoldAtGte
            ebaySaleSoldAtLte: $ebaySaleSoldAtLte
            itemBrandId: $itemBrandId
            itemCategoryId: $itemCategoryId
            sourceLocationId: $sourceLocationId
            tags: $tags
        ) {
            id
            itemName
            imageUrl
            profitAndRoi {
                totalProfitLoss
                totalReturnOnInvestment
                totalRevenue
                totalCosts
            }
            ebayItemId
        }
    }
`;

export const MISSING_DATA_COUNT_QUERY = gql`
    query MissingDataCountQuery {
        salesMissingDataCount
        inventoryMissingDataCount
    }
`;
