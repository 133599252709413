import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoComponent from "@/components/logo/LogoComponent";
import CircularLoader from "@/components/loading/LoadingComponent";
import { useMutation } from "@apollo/client";
import { REQUEST_PASSWORD_RESET_MUTATION } from "@/graphql/mutations/authMutations";

export default function RequestResetPasswordPage() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [requestPasswordReset, { loading, error }] = useMutation(
        REQUEST_PASSWORD_RESET_MUTATION,
    );

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const { data } = await requestPasswordReset({
                variables: { email },
            });
            if (data?.requestPasswordReset?.success) {
                setMessage(
                    "If an account exists with that email, you will receive a reset link shortly.",
                );
            }
        } catch (err) {
            console.error("Failed to request password reset", err);
        }
    };

    if (loading) {
        return <CircularLoader />;
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Request Password Reset
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 dark:text-white"
                        >
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-brand focus:ring-2 focus:ring-inset focus:ring-brand dark:text-white dark:ring-white/10 dark:focus:ring-brand sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
                        >
                            Request Reset Link
                        </button>
                    </div>
                </form>

                {message && (
                    <p className="mt-4 text-center text-sm dark:text-gray-400">
                        {message}
                    </p>
                )}
                {error && (
                    <p className="mt-4 text-center text-sm text-red-500">
                        Error occurred. Please try again.
                    </p>
                )}

                <p className="mt-10 text-center text-sm dark:text-gray-400">
                    Back to{" "}
                    <Link
                        to="/login"
                        className="font-semibold leading-6 text-brand hover:text-brand"
                    >
                        Login
                    </Link>
                </p>
            </div>
        </div>
    );
}
