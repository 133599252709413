import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation } from "@apollo/client";
import { RESET_USER_PASSWORD_MUTATION } from "@/graphql/mutations/settingsMutations";
import ModalCircularLoader from "@/components/loading/ModalLoadingComponent";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

const PasswordUpdateModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
    const navigate = useNavigate();

    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [confirmPasswordError, setConfirmPasswordError] =
        useState<string>("");
    const [generalError, setGeneralError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const [resetUserPassword, { loading }] = useMutation(
        RESET_USER_PASSWORD_MUTATION,
        {
            onError: (error) => {
                setGeneralError(error.message || "Failed to update password.");
            },
            onCompleted: () => {
                setSuccessMessage("Password successfully updated!");
                setTimeout(() => {
                    navigate(0);
                }, 5000); // Close modal after 5 seconds
            },
        },
    );

    const validatePassword = (password: string): boolean => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/;
        return passwordRegex.test(password);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
        setPasswordError(
            validatePassword(e.target.value)
                ? ""
                : "Password must be 12+ characters, contain a capital letter, lowercase letter, and a number.",
        );
    };

    const handleConfirmPasswordChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError(
            e.target.value === newPassword ? "" : "Passwords do not match.",
        );
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setGeneralError(null); // Reset error message

        if (!validatePassword(newPassword)) {
            setPasswordError(
                "Password must be 12+ characters, contain a capital letter, lowercase letter, and a number.",
            );
            return;
        }

        if (newPassword !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match.");
            return;
        }

        try {
            await resetUserPassword({
                variables: {
                    oldPassword,
                    newPassword,
                },
            });
        } catch (error) {
            console.error("Error resetting password:", error);
        }
    };

    const handleModalClose = () => {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setPasswordError("");
        setConfirmPasswordError("");
        setGeneralError(null);
        setSuccessMessage(null);
        closeModal();
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={handleModalClose}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 border-2 bg-white bg-opacity-75 transition-opacity dark:bg-gray-500 dark:opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg border-2 border-gray-900 bg-white px-4 pb-4 text-left shadow-xl transition-all dark:border-0 dark:bg-gray-900 sm:my-8 sm:w-full sm:max-w-sm sm:p-6 lg:pt-4">
                                <div className="mb-3">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 dark:text-white"
                                    >
                                        Update Password
                                    </Dialog.Title>
                                </div>

                                {loading ? (
                                    <ModalCircularLoader />
                                ) : successMessage ? (
                                    <p className="text-sm text-green-600 dark:text-green-400">
                                        {successMessage}
                                    </p>
                                ) : (
                                    <form onSubmit={handleSubmit}>
                                        <div className="space-y-4">
                                            {generalError && (
                                                <p className="text-sm text-red-500 dark:text-red-400">
                                                    {generalError}
                                                </p>
                                            )}
                                            <div className="mt-1">
                                                <label
                                                    htmlFor="oldPassword"
                                                    className="block text-sm font-medium dark:text-white"
                                                >
                                                    Current Password
                                                </label>
                                                <input
                                                    id="oldPassword"
                                                    type="password"
                                                    required
                                                    value={oldPassword}
                                                    onChange={(e) =>
                                                        setOldPassword(
                                                            e.target.value,
                                                        )
                                                    }
                                                    className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand dark:bg-gray-700 dark:text-white sm:text-sm"
                                                />
                                            </div>

                                            <div className="mt-1">
                                                <label
                                                    htmlFor="newPassword"
                                                    className="block text-sm font-medium dark:text-white"
                                                >
                                                    New Password
                                                </label>
                                                <input
                                                    id="newPassword"
                                                    type="password"
                                                    required
                                                    value={newPassword}
                                                    onChange={
                                                        handlePasswordChange
                                                    }
                                                    className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand dark:bg-gray-700 dark:text-white sm:text-sm"
                                                />
                                                {passwordError && (
                                                    <p className="mt-2 text-sm text-red-500 dark:text-red-400">
                                                        {passwordError}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="mt-1">
                                                <label
                                                    htmlFor="confirmPassword"
                                                    className="block text-sm font-medium dark:text-white"
                                                >
                                                    Confirm New Password
                                                </label>
                                                <input
                                                    id="confirmPassword"
                                                    type="password"
                                                    required
                                                    value={confirmPassword}
                                                    onChange={
                                                        handleConfirmPasswordChange
                                                    }
                                                    className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand dark:bg-gray-700 dark:text-white sm:text-sm"
                                                />
                                                {confirmPasswordError && (
                                                    <p className="mt-2 text-sm text-red-500 dark:text-red-400">
                                                        {confirmPasswordError}
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="mt-4 flex justify-end space-x-2">
                                            <button
                                                type="button"
                                                className="flex w-full justify-center rounded-md border-2 border-gray-900 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm dark:border-0 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
                                                onClick={handleModalClose}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand dark:text-white"
                                            >
                                                Update Password
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default PasswordUpdateModal;
