import { gql } from "@apollo/client";

export const SEARCH_QUERY = gql`
    query SearchQuery($searchTerm: String!) {
        search(searchTerm: $searchTerm) {
            __typename
            ... on EbayItemType {
                id
                itemName
                ebayItemId
                listedAt
                listedPrice
                costOfGoodsPerItem
            }
            ... on EbaySaleType {
                id
                ebayOrderId
                ebayItem {
                    id
                    ebayItemId
                    itemName
                    listedAt
                    listedPrice
                    costOfGoodsPerItem
                }
            }
        }
    }
`;
